import React, { Component, Suspense, lazy } from 'react'
import { Provider } from 'react-redux'
import store from './store'

import { Route, BrowserRouter } from 'react-router-dom'
import Switch from 'react-router-transition-switch'
import Fader from 'react-fader'
import { NotificationManager, NotificationContainer } from 'react-notifications'
import { Lines } from 'react-preloaders'
import io from 'socket.io-client'

import Authorize from './Authorize'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-sliding-pane/dist/react-sliding-pane.css'
import 'react-notifications/lib/notifications.css'
import './public/css/atlantis.min.css'
import './public/css/demo.css'
import './public/css/style.css'
import './public/css/fonts.min.css'
import './public/css/animation-style.css'
import './App.css'
import 'react-inputs-validation/lib/react-inputs-validation.min.css'

const ErrorHandler = lazy(() => import('./ErrorHandler'))
const NotFound = lazy(() => import('./components/common/NotFound'))
const Landing = lazy(() => import('./components/Landing'))
// const Email = lazy(() => import('./components/Email'))
const LoginRedirect = lazy(() => import('./components/LoginRedirect'))
const VerifyEmail = lazy(() => import('./components/VerifyEmail'))
const VerifyHometeam = lazy(() => import('./components/VerifyHometeam'))
const AllNotifications = lazy(() => import('./components/common/AllNotifications'))
const Notification = lazy(() => import('./components/common/Notification'))
// const Search = lazy(() => import('./components/Search'))
const SearchAddressModal = lazy(() => import('./components/SearchAddressModal'))
const SearchAddress = lazy(() => import('./components/SearchAddress'))
const GeneralSponsorship = lazy(() => import('./components/GeneralSponsorship/index'))
const SelectProfile = lazy(() => import('./components/SelectProfile'))
const Unsubscribe = lazy(() => import('./components/Unsubscribe'))

/* Homeowner */
const Dashboard = lazy(() => import('./components/Dashboard'))
const HomeownerOnboarding = lazy(() => import('./components/HomeownerOnboarding'))
const FinancialHistory = lazy(() => import('./components/FinancialHistory'))
const Register = lazy(() => import('./components/Register'))
const RegisterInventory = lazy(() => import('./components/RegisterInventory'))
const RegisterHomeTeam = lazy(() => import('./components/RegisterHomeTeam'))
const RegisterCalendars = lazy(() => import('./components/RegisterCalendars'))
const RegisterHomeInformation = lazy(() => import('./components/RegisterHomeInformation'))
const RegistorReplacementBudget = lazy(() => import('./components/RegistorReplacementBudget'))
const TipDetail = lazy(() => import('./components/TipDetail'))
const HomeownerReport = lazy(() => import('./components/HomeownerReport'))
const EnterPhone = lazy(() => import('./components/EnterPhone'))

/* Realtor */
const RealtorDashboard = lazy(() => import('./components/RealtorDashboard'))
// const RealtorAddClient = lazy(() => import('./components/RealtorAddClient'))
// const RealtorAddProfessional = lazy(() => import('./components/RealtorAddProfessional'))
// const RealtorOrderClientSubscription = lazy(() =>
//   import('./components/RealtorOrderClientSubscription'),
// )
// const RealtorOrderClientGift = lazy(() => import('./components/RealtorOrderClientGift'))
// const RealtorOrderThanksLetter = lazy(() => import('./components/RealtorOrderThanksLetter'))
// const RealtorThanksLetterCompose = lazy(() => import('./components/RealtorThanksLetterCompose'))
// const RealtorOrderSummary = lazy(() => import('./components/RealtorOrderSummary'))
// const RealtorOrderComplete = lazy(() => import('./components/RealtorOrderComplete'))
const RegisterProfessional = lazy(() => import('./components/RegisterProfessional'))
const InviteClients = lazy(() => import('./components/InviteClients'))
const SponsorClients = lazy(() => import('./components/SponsorClients'))
// const AgentProfile = lazy(() => import('./components/AgentProfile'))

/* Builder */
// const BuilderDashboard = lazy(() => import('./components/BuilderDashboard'))
// const BuilderAddClient = lazy(() => import('./components/BuilderAddClient'))
// const BuilderHomeInventory = lazy(() => import('./components/BuilderHomeInventory'))
// const BuilderSubContractor = lazy(() => import('./components/BuilderSubContractor'))

/* Professional */
// const ProfessionalDashboard = lazy(() => import('./components/ProfessionalDashboard'))

/* PropertyManager */
const PropertyManagerDashboard = lazy(() => import('./components/PropertyManager/index'))
const PropertyManagerProfile = lazy(() => import('./components/PropertyManager/Profile'))
const PropertyManagerPropertyList = lazy(() => import('./components/PropertyManager/PropertyList'))

const ReviewForm = lazy(() => import('./components/ReviewForm'))

let versionMsg = false
class App extends Component {
  constructor(props) {
    super()
    this.state = {
      visible: true,
    }
    // console.log('init')

    //Connecting to socket
    let socket = io.connect(process.env.REACT_APP_SERVER_URL, { transports: ['websocket'] })
    socket.on('version-check', currentVersion => {
      const localVersion = window.localStorage.getItem('client-version')
      // console.log(currentVersion, localVersion)
      if (!localVersion) {
        window.localStorage.setItem('client-version', currentVersion)
      } else if (parseFloat(localVersion) !== currentVersion && !versionMsg) {
        versionMsg = true
        NotificationManager.info(
          'Click to refresh and see updates.',
          'HomeManager Updated!',
          1000 * 60 * 60,
          () => {
            window.localStorage.setItem('client-version', currentVersion)
            window.location.reload(true)
          },
          true,
        )
        setTimeout(() => {
          window.localStorage.setItem('client-version', currentVersion)
          window.location.reload(true)
        }, 1000 * 60 * 60)
        window.onbeforeunload = function() {
          window.localStorage.setItem('client-version', currentVersion)
        }
      }
    })
  }

  componentDidMount = async () => {
    // console.log('mount')
    // const { token, isAuthenticated } = store.getState().auth
    // if(token!==null)
    // store.dispatch(loadUser());
    this.loaderTimeOut().then(() => {
      this.setState({
        visible: false,
      })
    })
  }

  componentDidUpdate = prevProps => {
    // console.log('update')
  }

  loaderTimeOut = () => {
    return new Promise(resolve => {
      setTimeout(() => resolve(), 800)
    })
  }

  render() {
    return (
      <BrowserRouter>
        <Provider store={store}>
          <Suspense
            fallback={
              <div
                style={{
                  background: 'center/7% no-repeat url(/img/logo-loader-anime.gif) transparent',
                }}
              ></div>
            }
          >
            <ErrorHandler>
              <Authorize history={this.props.history}>
                <Switch component={Fader}>
                  <Route path="/" exact component={Landing} />
                  <Route path="/(login|logout)" exact component={Register} />
                  <Route path="/forgot-password" exact component={Register} />
                  <Route path="/reset-password/:key+" exact component={Register} />
                  <Route path="/register/:userType?" component={Register} />
                  <Route path="/search-home/:key?" component={SearchAddress} />
                  <Route path="/homeowner-report/:key?" component={HomeownerReport} />
                  <Route path="/verify-email/:key+" exact component={VerifyEmail} />
                  <Route path="/hometeam-status/:key+/:action+" component={VerifyHometeam} />
                  <Route path="/all-notifications" exact component={AllNotifications} />
                  <Route
                    path="/address-search"
                    exact
                    render={props => <SearchAddressModal show={true} userType="homeowner" />}
                  />
                  <Route path="/homeowner" component={Dashboard} />
                  {/* HomeownerOnboarding Steps */}
                  <Route path="/homeowner-onboarding" component={HomeownerOnboarding} />
                  <Route path="/financial-history" component={FinancialHistory} />
                  <Route path="/login-redirect/:page?" component={LoginRedirect} />
                  <Route
                    path="/register-inventory-(interior|exterior)"
                    component={RegisterInventory}
                  />
                  <Route path="/register-hometeam" component={RegisterHomeTeam} />
                  <Route path="/replacement-budget" component={RegistorReplacementBudget} />
                  <Route path="/register-calendars" component={RegisterCalendars} />
                  <Route
                    path="/register-homeinformation/:property_id?/:sponsor_invitation_key?"
                    component={RegisterHomeInformation}
                  />
                  <Route exact path="/homeowner-enterphone" component={EnterPhone} />

                  <Route path="/(realtor|builder|professional)" component={RealtorDashboard} />
                  {/* Onboarding steps */}
                  {/* <Route path="/(realtor|builder)-agent-profile" component={AgentProfile} /> */}
                  <Route path="/register-professional" component={RegisterProfessional} />
                  {/* Add new Client steps */}
                  {/* <Route path="/add-client" component={RealtorAddClient} />
                  <Route path="/add-professional" component={RealtorAddProfessional} />
                  <Route
                    path="/add-client-subscription"
                    component={RealtorOrderClientSubscription}
                  />
                  <Route path="/add-client-gift" component={RealtorOrderClientGift} />
                  <Route path="/add-letter-tempalate" component={RealtorOrderThanksLetter} />
                  <Route path="/add-letter-compose" component={RealtorThanksLetterCompose} />
                  <Route path="/add-order-summary" component={RealtorOrderSummary} />
                  <Route path="/add-order-placed" component={RealtorOrderComplete} /> */}
                  <Route path="/invite-clients" component={InviteClients} />
                  <Route path="/sponsor-clients" component={SponsorClients} />
                  {/* <Route path="/builder" component={BuilderDashboard} /> */}
                  {/* Onboarding steps */}
                  {/* <Route path="/builder-add-client" component={BuilderAddClient} />
                  <Route path="/builder-home-inventory" component={BuilderHomeInventory} />
                  <Route
                    path="/(builder|realtor)-sub-contractor)"
                    component={BuilderSubContractor}
                    exact
                  /> */}

                  {/* <Route path="/professional" component={ProfessionalDashboard} /> */}

                  <Route path="/property-manager" component={PropertyManagerDashboard} />
                  <Route path="/property-manager-profile" component={PropertyManagerProfile} />
                  <Route
                    path="/property-manager-add-property"
                    component={PropertyManagerPropertyList}
                  />

                  <Route path="/get-review/:id" component={ReviewForm} />

                  <Route path="/select-profile" component={SelectProfile} />
                  <Route path="/general-sponsorship/:key?" component={GeneralSponsorship} />
                  <Route path="/tip/:id?" component={TipDetail} />
                  <Route path="/notifications" component={Notification} />
                  <Route path="/email-unsubscribe" component={Unsubscribe} />
                  <Route path="/error" component={ErrorHandler} />
                  <Route component={NotFound} />
                </Switch>
              </Authorize>

              <Lines
                color="transparent"
                background="center/7% no-repeat url(/img/logo-loader-anime.gif) transparent"
                customLoading={this.state.visible}
                // time={0}
              />

              <NotificationContainer />
            </ErrorHandler>
          </Suspense>
        </Provider>
      </BrowserRouter>
    )
  }
}

export default App
