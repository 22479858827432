import { combineReducers } from 'redux'
// import itemReducer from './itemReducer'
import errorReducer from './errorReducer'
import authReducer from './authReducer'
import homeReducer from './homeReducer'
import userReducer from './userReducer'
import tasksReducer from './tasksReducer'
import foldersReducer from './foldersReducer'
import adminsettingReducer from './adminsettingReducer'
import plansReducer from './plansReducer'
import tipsReducer from './tipsReducer'
import sponsorsReducer from './sponsorsReducer'
import holidays from './holidaysReducer'
import giftsReducer from './giftsReducer'
import giveAwayReducer from './giveAwayReducer'
import templatesReducer from './templatesReducer'
import cartReducer from './cartReducer'
import contractTemplatesReducer from './contractTemplatesReducer'
import paymentsReducer from './paymentsReducer'
import reviews from './reviewsReducer'
import realtor from './realtorReducer'
import builder from './builderReducer'
import replacementBudget from './replacementBudgetReducer'
import generalNotes from './generalNotesReducer'
import report from './reportReducer'
import generalSponsorship from './generalSponsorshipReducer'
import propertymanager from './propertymanagerReducer'

export default combineReducers({
  adminsetting: adminsettingReducer,
  plans: plansReducer,
  error: errorReducer,
  auth: authReducer,
  home: homeReducer,
  user: userReducer,
  tasks: tasksReducer,
  folders: foldersReducer,
  tips: tipsReducer,
  sponsors: sponsorsReducer,
  gifts: giftsReducer,
  giveAways: giveAwayReducer,
  templates: templatesReducer,
  cart: cartReducer,
  contractTemplates: contractTemplatesReducer,
  payments: paymentsReducer,
  reviews,
  realtor,
  builder,
  replacementBudget,
  holidays,
  generalNotes,
  report,
  generalSponsorship,
  propertymanager,
})
