import {
  GIFTS_LOADING,
  GIFTS_LOADED,
  GIFTS_LOAD_ERROR
} from "../actions/types";

const initialState = {
  loading: false,
  gifts: [],
  error: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GIFTS_LOADING:
      return {
        ...state,
        loading: true
      };
    case GIFTS_LOADED:
      return {
        ...state,
        loading: false,
        gifts: action.payload.data
      };
    case GIFTS_LOAD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
