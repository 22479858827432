import {
  LOAD_GIFT_CART,
  CLEAR_GIFT_CART,
  ADDING_GIFT_CART,
  REMOVING_GIFT_CART,
  ADDING_PLAN_CART,
  REMOVING_PLAN_CART,
  ADDING_LETTER_INFO,
  ADDING_CLIENT_INFO,
  ADDING_CART,
  AUTH_ERROR,
  LOGOUT_SUCCESS,
  EMPTY_CART,
} from '../actions/types'

import { saveCartInfo } from '../utils'

const initialState = {
  plan: null,
  gifts: [],
  discountGift: [],
  total: 0,
  letterInfo: {},
  clientInfo: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case ADDING_CART:
      return {
        ...action.payload,
      }
    case LOAD_GIFT_CART:
      return {
        ...(typeof window.localStorage.cartInfo != 'undefined'
          ? JSON.parse(window.localStorage.getItem('cartInfo'))
          : state),
      }
    case CLEAR_GIFT_CART:
      window.localStorage.removeItem('cartInfo')
      return {
        ...state,
      }
    case ADDING_GIFT_CART:
      let giftPrice = parseFloat(action.payload.price)
      if (state.gifts.length === 0) {
        giftPrice = parseFloat(action.payload.price) - 25
      }

      let totalPrice = parseInt(state.total)
      if (totalPrice < state.plan.price) {
        totalPrice = state.plan.price
      }

      const addGiftData = {
        ...state,
        gifts: [...state.gifts, action.payload],
        total: totalPrice + giftPrice,
        discountGift: action.payload,
      }
      saveCartInfo(addGiftData)
      return addGiftData
    case REMOVING_GIFT_CART:
      const _gifts = [...state.gifts]
      const filteredGifts = _gifts.filter(gift => gift._id !== action.payload._id)

      let totalCartAmount = parseInt(state.total)
      if (totalCartAmount - parseFloat(action.payload.price) < state.plan.price) {
        totalCartAmount = state.plan.price
      } else {
        totalCartAmount -= parseFloat(action.payload.price)
      }
      const removeGiftData = {
        ...state,
        gifts: filteredGifts,
        total: totalCartAmount,
      }

      saveCartInfo(removeGiftData)
      return removeGiftData
    case ADDING_PLAN_CART:
      const addPlanData = {
        ...state,
        plan: action.payload,
        total: parseFloat(action.payload.price),
      }
      saveCartInfo(addPlanData)
      return addPlanData
    case REMOVING_PLAN_CART:
      const removePlanData = {
        ...state,
        plan: null,
        total: parseFloat(state.total) - parseFloat(action.payload.price),
      }
      saveCartInfo(removePlanData)
      return removePlanData
    case ADDING_LETTER_INFO:
      const addLetterData = {
        ...state,
        letterInfo: action.payload,
      }
      saveCartInfo(addLetterData)
      return addLetterData
    case ADDING_CLIENT_INFO:
      const addClientData = {
        ...state,
        clientInfo: action.payload,
      }
      saveCartInfo(addClientData)
      return addClientData
    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
      window.localStorage.removeItem('token')
      return {
        ...state,
        ...initialState,
      }
    case EMPTY_CART:
      window.localStorage.removeItem('lastPaymentId')
      return {
        plan: null,
        gifts: [],
        discountGift: [],
        total: 0,
        letterInfo: {},
        clientInfo: null,
      }
    default:
      // saveCartInfo(state);
      return state
  }
}
