import {
  GET_FILTER_ITEMS,
  FILTER_ITEMS,
  REPLACEMENT_BUDGET_SEARCHING,
  REPLACEMENT_BUDGET_SEARCHED,
  REPLACEMENT_BUDGET_LOADING,
  REPLACEMENT_BUDGET_LOADED,
  REPLACEMENT_BUDGET_ADDING,
  REPLACEMENT_BUDGET_ADDED,
  GET_APPLIANCES_EQUIPMENTS,
  AUTH_ERROR,
  LOGOUT_SUCCESS,
} from '../actions/types'

const initialState = {
  loading: false,
  saving: false,
  filterItems: null,
  data: null,
  search: null,
  source: null,
  recalls: null,
  appliances_equipments: [],
}

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_FILTER_ITEMS:
    case FILTER_ITEMS:
    case REPLACEMENT_BUDGET_SEARCHING:
    case REPLACEMENT_BUDGET_LOADING:
      return {
        ...state,
        loading: true,
      }
    case REPLACEMENT_BUDGET_SEARCHED:
    case REPLACEMENT_BUDGET_LOADED:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case REPLACEMENT_BUDGET_ADDING:
      return {
        ...state,
        saving: true,
      }
    case REPLACEMENT_BUDGET_ADDED:
      return {
        ...state,
        ...action.payload,
        saving: false,
      }
    case GET_APPLIANCES_EQUIPMENTS:
      return {
        ...state,
        ...action.payload,
      }
    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
      window.localStorage.removeItem('token')
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}
