import {
  LOADING_NOTES,
  LOADED_NOTES,
  SAVING_NOTE,
  SAVED_NOTE,
  DELETING_NOTE,
  DELETED_NOTE,
  UPDATED_NOTE,
} from '../actions/types'

const initialState = {
  data: null,
  isLoading: false,
  isSaving: false,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_NOTES:
      return {
        ...state,
        isLoading: true,
      }
    case LOADED_NOTES:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      }
    case SAVING_NOTE:
    case DELETING_NOTE:
      return {
        ...state,
        isSaving: true,
      }
    case SAVED_NOTE:
    case DELETED_NOTE:
    case UPDATED_NOTE:
      return {
        ...state,
        isSaving: false,
        ...action.payload,
      }
    default:
      return state
  }
}
