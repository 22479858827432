/*eslint-disable*/
import {
  ADDED_REALTOR_CLIENT,
  ADDING_REALTOR_CLIENT,
  LOADING_CLIENTS,
  LOADED_CLIENTS,
  DOWNLOADING_CLIENTS,
  DOWNLOADED_CLIENTS,
  LOADING_TRANSACTION_TEAM,
  LOADED_TRANSACTION_TEAM,
  LOADING_EVALUATION_REQUESTS,
  LOADED_EVALUATION_REQUESTS,
  LOADING_LEADS,
  LOADED_LEADS,
  SAVING_LEAD,
  SAVED_LEAD,
  LOADING_STATS,
  LOADED_STATS,
  LOADING_PROFESSIONALS,
  LOADED_PROFESSIONALS,
  INVITING_USER,
  INVITED_USER,
  SUBMITTING_INVITE_RESPONSE,
  SUBMITTED_INVITE_RESPONSE,
  LOADING_PENDING_INVITES,
  LOADED_PENDING_INVITES,
  LOADING_REFERRALS,
  LOADED_REFERRALS,
  SENDING_REFERRAL,
  SENT_REFERRAL,
  SEND_REFERRAL_ERROR,
  LOGOUT_SUCCESS,
  AUTH_ERROR,
  ADDING_BUYER_INTEREST,
  BUYER_INTEREST_ADDED,
  BUYER_INTEREST_LOADED,
  BUYER_INTEREST_LOADING,
} from '../actions/types'

const initialState = {
  isLoading: false,
  isSaving: false,
  isDownloading: false,
  clients: null,
  pending_sponsor: null,
  transaction_team: null,
  evaluation_requests: null,
  leads: null,
  isLoadingLeads: false,
  isSavingLead: false,
  dashboard_stats: null,
  isLoadingStats: false,
  professionals: null,
  isLoadingProfessionals: false,
  pending_invites: null,
  invitesCount: 0,
  referrals: null,
  buyerInterestLoading: null,
  buyerCountLoading: false,
  buyerInterestCount: 0,
  allBuyerInterests: [],
}

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_CLIENTS:
    case LOADING_TRANSACTION_TEAM:
    case LOADING_EVALUATION_REQUESTS:
    case LOADING_PENDING_INVITES:
      return {
        ...state,
        isLoading: !state.isLoading,
      }
    case LOADED_CLIENTS:
    case LOADED_TRANSACTION_TEAM:
    case LOADED_EVALUATION_REQUESTS:
    case LOADED_PENDING_INVITES:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      }
    case DOWNLOADING_CLIENTS:
      return {
        ...state,
        isDownloading: true,
      }
    case DOWNLOADED_CLIENTS:
      return {
        ...state,
        isDownloading: false,
      }
    case LOADING_LEADS:
      return {
        ...state,
        isLoadingLeads: true,
      }
    case LOADED_LEADS:
      return {
        ...state,
        ...action.payload,
        isLoadingLeads: false,
      }
    case SAVING_LEAD:
      return {
        ...state,
        isSavingLead: true,
      }
    case SAVED_LEAD:
      return {
        ...state,
        ...action.payload,
        isSavingLead: false,
      }
    case LOADING_STATS:
      return {
        ...state,
        isLoadingStats: true,
      }
    case LOADED_STATS:
      return {
        ...state,
        ...action.payload,
        isLoadingStats: false,
      }
    case LOADING_PROFESSIONALS:
      return {
        ...state,
        isLoadingProfessionals: true,
      }
    case LOADED_PROFESSIONALS:
      return {
        ...state,
        ...action.payload,
        isLoadingProfessionals: false,
      }
    case INVITING_USER:
    case INVITED_USER:
    case SUBMITTING_INVITE_RESPONSE:
    case SUBMITTED_INVITE_RESPONSE:
      return {
        ...state,
        inviteLoading: !state.inviteLoading,
      }
    case SENDING_REFERRAL:
    case ADDING_REALTOR_CLIENT:
      return {
        ...state,
        isSaving: true,
      }
    case SENT_REFERRAL:
    case ADDED_REALTOR_CLIENT:
      return {
        ...state,
        ...action.payload,
        isSaving: false,
      }
    case SEND_REFERRAL_ERROR:
      return {
        ...state,
        ...action.payload,
        isSaving: false,
      }
    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
      window.localStorage.removeItem('token')
      return {
        ...state,
        ...initialState,
      }
    case ADDING_BUYER_INTEREST:
    case BUYER_INTEREST_ADDED:
      return {
        ...state,
        buyerInterestLoading: !state.buyerInterestLoading,
      }
    case BUYER_INTEREST_LOADING:
      return {
        ...state,
        buyerCountLoading: true,
      }
    case BUYER_INTEREST_LOADED:
      return {
        ...state,
        ...action.payload,
        buyerCountLoading: false,
      }
    default:
      return state
  }
}
