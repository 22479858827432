import {
  AUTH_ERROR,
  LOGOUT_SUCCESS,
  GETTING_HOME_ESTATED,
  GET_HOME_ESTATED,
  GET_HOME_ESTATED_FAILED,
  CLEAR_HOME_ESTATED,
  GET_HOME_DETAILS,
  GETTING_HOME_DETAILS,
  SAVING_HOME_DETAILS,
  SAVED_HOME_DETAILS,
  REMOVING_OWNER,
  REMOVED_OWNER,
  REMOVING_OWNER_FAILED,
  CLAIMING_OWNERSHIP,
  CLAIM_OWNERSHIP_SUBMITTED,
  CLAIMING_OWNERSHIP_FAILED,
  REQUESTED_EVALUATION,
  REQUESTING_EVALUATION,
  REQUEST_EVALUATION_ERROR,
  REQUESTING_WARRANTY,
  REQUESTED_WARRANTY,
  REQUEST_WARRANTY_ERROR,
  SPONSOR_REMOVAL_REQUESTING,
  SPONSOR_REMOVAL_REQUESTED,
} from '../actions/types'

const initialState = {
  isLoading: false,
  isSaving: false,
  estate_data: null,
  myproperties: null,
  mypropertiesHomeIds: null,
  claim: null,
  estate_claim: null,
  buyerInterests: [],
}

export default function(state = initialState, action) {
  switch (action.type) {
    case GETTING_HOME_ESTATED:
    case GETTING_HOME_DETAILS:
    case SPONSOR_REMOVAL_REQUESTING:
      return {
        ...state,
        isLoading: true,
      }
    case GET_HOME_ESTATED:
    case GET_HOME_DETAILS:
    case GET_HOME_ESTATED_FAILED:
    case SPONSOR_REMOVAL_REQUESTED:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      }
    case SAVING_HOME_DETAILS:
    case REMOVING_OWNER:
    case CLAIMING_OWNERSHIP:
    case REQUESTED_EVALUATION:
    case REQUESTED_WARRANTY:
      return {
        ...state,
        isSaving: true,
      }
    case SAVED_HOME_DETAILS:
    case REMOVED_OWNER:
    case CLAIM_OWNERSHIP_SUBMITTED:
    case REQUESTING_EVALUATION:
    case REQUESTING_WARRANTY:
      return {
        ...state,
        isSaving: false,
        ...action.payload,
      }
    case REMOVING_OWNER_FAILED:
    case CLAIMING_OWNERSHIP_FAILED:
    case REQUEST_EVALUATION_ERROR:
    case REQUEST_WARRANTY_ERROR:
      return {
        ...state,
        isSaving: false,
        ...action.payload,
      }
    case CLEAR_HOME_ESTATED:
      return {
        ...state,
        ...action.payload,
      }
    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}
