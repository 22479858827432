import { SPONSORS_DETAILS, SPONSORS_LOADING, SPONSOR_DETAIL } from '../actions/types'

const initialState = {
  data: null,
  isLoading: false,
  singleData: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SPONSORS_DETAILS:
    case SPONSOR_DETAIL:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      }
    case SPONSORS_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    default:
      return state
  }
}
