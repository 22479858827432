import {
  TEMPLATES_LOADING,
  TEMPLATES_LOADED,
  TEMPLATES_LOAD_ERROR,
  TEMPLATE_SAVING,
  TERMPLATE_SAVED
} from "../actions/types";

const initialState = {
  loading: false,
  templates: [],
  template: [],
  error: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case TEMPLATES_LOADING:
      return {
        ...state,
        loading: true
      };
    case TEMPLATES_LOADED:
      return {
        ...state,
        loading: false,
        templates: action.payload.data
      };
    case TEMPLATES_LOAD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
