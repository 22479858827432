import {
  CONTRACT_DOCUMENTS_LOADING,
  CONTRACT_DOCUMENTS_LOADED
} from "../actions/types";

const initialState = {
  loadingContractDocuments: false,
  documents: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CONTRACT_DOCUMENTS_LOADING:
      return {
        ...state,
        loadingContractDocuments: !state.loadingContractDocuments
      };
    case CONTRACT_DOCUMENTS_LOADED:
      return {
        ...state,
        documents: action.payload,
        loadingContractDocuments: !state.loadingContractDocuments
      };
    default:
      return state;
  }
}
