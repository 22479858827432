import axios from 'axios'
import { GETTING_ADMINSETTING, GET_ADMINSETTING } from './types'
import { tokenConfig } from './authActions'
import { returnErrors } from './errorActions'

const baseurl = process.env.REACT_APP_SERVER_URL

export const getAdminsetting = (req, cb) => (dispatch, getState) => {
	dispatch({
		type: GETTING_ADMINSETTING,
	})
	axios.get(baseurl + '/api/admin-setting', tokenConfig(getState))
		.then(res => {
			if (cb) cb(res.data.version)
			dispatch({
				type: GET_ADMINSETTING,
				payload: res.data,
			})
		})
}
