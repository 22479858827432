export const GET_ITEMS = 'GET_ITEMS'
export const ADD_ITEM = 'ADD_ITEM'
export const DELETE_ITEM = 'DELETE_ITEM'
export const ITEMS_LOADING = 'ITEMS_LOADING'

// auth actions
export const LOADED_AUTHCONFIG = 'LOADED_AUTHCONFIG'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const GET_ERRORS = 'GET_ERRORS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const GET_ADMINSETTING = 'GET_ADMINSETTING'
export const GETTING_ADMINSETTING = 'GETTING_ADMINSETTING'

// homeinfo actions
export const GETTING_HOME_ESTATED = 'GETTING_HOME_ESTATED'
export const GET_HOME_ESTATED = 'GET_HOME_ESTATED'
export const GET_HOME_ESTATED_FAILED = 'GET_HOME_ESTATED_FAILED'
export const CLEAR_HOME_ESTATED = 'CLEAR_HOME_ESTATED'
export const GET_HOME_DETAILS = 'GET_HOME_DETAILS'
export const GETTING_HOME_DETAILS = 'GETTING_HOME_DETAILS'
export const SAVING_HOME_DETAILS = 'SAVING_HOME_DETAILS'
export const SAVING_HOME_DETAILS_FAILED = 'SAVING_HOME_DETAILS_FAILED'
export const SAVED_HOME_DETAILS = 'SAVED_HOME_DETAILS'
export const REMOVING_OWNER = 'REMOVING_OWNER'
export const REMOVED_OWNER = 'REMOVED_OWNER'
export const REMOVING_OWNER_FAILED = 'REMOVING_OWNER_FAILED'
export const CLAIMING_OWNERSHIP = 'CLAIMING_OWNERSHIP'
export const CLAIM_OWNERSHIP_SUBMITTED = 'CLAIM_OWNERSHIP_SUBMITTED'
export const CLAIMING_OWNERSHIP_FAILED = 'CLAIMING_OWNERSHIP_FAILED'
export const REQUESTED_EVALUATION = 'REQUESTED_EVALUATION'
export const REQUESTING_EVALUATION = 'REQUESTING_EVALUATION'
export const REQUEST_EVALUATION_ERROR = 'REQUEST_EVALUATION_ERROR'
export const REQUESTING_WARRANTY = 'REQUESTING_WARRANTY'
export const REQUESTED_WARRANTY = 'REQUESTED_WARRANTY'
export const REQUEST_WARRANTY_ERROR = 'REQUEST_WARRANTY_ERROR'

// user actions
export const USER_LOADING = 'USER_LOADING'
export const USER_LOADED = 'USER_LOADED'
export const USER_PROFILE_UPDATING = 'USER_PROFILE_UPDATING'
export const USER_PROFILE_UPDATED = 'USER_PROFILE_UPDATED'
export const USER_PROFILE_UPDATE_FAIL = 'USER_PROFILE_UPDATE_FAIL'
export const USER_NOTIFICATION_GET = 'USER_NOTIFICATION_GET'
export const USER_NOTIFICATION_GET_FAILED = 'USER_NOTIFICATION_GET_FAILED'
export const USER_NOTIFICATION_UPDATING = 'USER_NOTIFICATION_UPDATING'
export const USER_NOTIFICATION_UPDATED = 'USER_NOTIFICATION_UPDATED'
export const USER_NOTIFICATION_UPDATE_FAIL = 'USER_NOTIFICATION_UPDATE_FAIL'
export const USER_AUTORENEW_UPDATING = 'USER_AUTORENEW_UPDATING'
export const USER_AUTORENEW_UPDATED = 'USER_AUTORENEW_UPDATED'
export const WEATHER_LOADING = 'WEATHER_LOADING'
export const WEATHER_LOADED = 'WEATHER_LOADED'
export const WEATHER_ERROR = 'WEATHER_ERROR'
export const HOME_TEAM_LOADED = 'HOME_TEAM_LOADED'
export const HOME_TEAM_LOADING = 'HOME_TEAM_LOADING'
export const SAVED_HOME_TEAM = 'SAVED_HOME_TEAM'
export const SAVING_HOME_TEAM = 'SAVING_HOME_TEAM'
export const SAVING_CALEDAR = 'SAVING_CALEDAR'
export const SAVED_CALEDAR = 'SAVED_CALEDAR'
export const LISTING_USERS = 'LISTING_USERS'
export const LISTED_USERS = 'LISTED_USERS'
export const PROSPECTIVE_TEAM_LOADING = 'PROSPECTIVE_TEAM_LOADING'
export const PROSPECTIVE_TEAM_LOADED = 'PROSPECTIVE_TEAM_LOADED'
export const SAVING_PROSPECTIVE_TEAM = 'SAVING_PROSPECTIVE_TEAM'
export const SAVED_PROSPECTIVE_TEAM = 'SAVED_PROSPECTIVE_TEAM'
export const DISCOVER_LOADING = 'DISCOVER_LOADING'
export const DISCOVER_LOADED = 'DISCOVER_LOADED'
export const ADDING_USER_PERMISSION = 'ADDING_USER_PERMISSION'
export const USER_PERMISSION_SUCCESS = 'USER_PERMISSION_SUCCESS'
export const USER_PERMISSION_ERROR = 'USER_PERMISSION_ERROR'
export const LOADING_USER_PERMISSIONS = 'LOADING_USER_PERMISSIONS'
export const USER_PERMISSIONS_SUCCESS = 'USER_PERMISSIONS_SUCCESS'
export const USER_PERMISSIONS_ERROR = 'USER_PERMISSIONS_ERROR'
export const ACCESS_USER_ACCOUNT = 'ACCESS_USER_ACCOUNT'
export const VERIFYING_EMAIL = 'VERIFYING_EMAIL'
export const VERIFIED_EMAIL = 'VERIFIED_EMAIL'
export const VERIFYING_HOMETEAM = 'VERIFYING_HOMETEAM'
export const VERIFIED_HOMETEAM = 'VERIFIED_HOMETEAM'
export const SUBMITTING_FORGOT_PASSWORD = 'SUBMITTING_FORGOT_PASSWORD'
export const SUBMITED_FORGOT_PASSWORD = 'SUBMITED_FORGOT_PASSWORD'
export const RESETING_PASSWORD = 'RESETING_PASSWORD'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const INVITING_USER = 'INVITING_USER'
export const INVITED_USER = 'INVITED_USER'
export const SUBMITTING_INVITE_RESPONSE = 'SUBMITTING_INVITE_RESPONSE'
export const SUBMITTED_INVITE_RESPONSE = 'SUBMITTED_INVITE_RESPONSE'
export const UPDATING_EMAIL = 'UPDATING_EMAIL'
export const UPDATED_EMAIL = 'UPDATED_EMAIL'
export const EXISTING_USER = 'EXISTING_USER'

// maintenance actions
export const TASKS_LOADING = 'TASKS_LOADING'
export const TASKS_LOADED = 'TASKS_LOADED'
export const YEAR_TASKS_LOADING = 'YEAR_TASKS_LOADING'
export const YEAR_TASKS_LOADED = 'YEAR_TASKS_LOADED'
export const ALL_USER_TASKS_LOADING = 'ALL_USER_TASKS_LOADING'
export const ALL_USER_TASKS_LOADED = 'ALL_USER_TASKS_LOADED'
export const ADMIN_TASKS_LOADING = 'ADMIN_TASKS_LOADING'
export const ADMIN_TASKS_LOADED = 'ADMIN_TASKS_LOADED'
export const SAVINGS_LOADING = 'SAVINGS_LOADING'
export const SAVINGS_LOADED = 'SAVINGS_LOADED'
export const TASK_STAUS_UPDATING = 'TASK_STAUS_UPDATING'
export const TASK_STAUS_UPDATED = 'TASK_STAUS_UPDATED'
export const TASK_SELECTION_UPDATING = 'TASK_SELECTION_UPDATING'
export const TASK_SELECTION_UPDATED = 'TASK_SELECTION_UPDATED'
export const TASK_CUSTOM_ADDING = 'TASK_CUSTOM_ADDING'
export const TASK_CUSTOM_ADDED = 'TASK_CUSTOM_ADDED'
export const TASK_CUSTOM_DELETING = 'TASK_CUSTOM_DELETING'
export const TASK_CUSTOM_DELETED = 'TASK_CUSTOM_DELETED'
export const TASKS_ERROR = 'TASKS_ERROR'

export const RECIEVER_LOADING = 'RECIEVER_LOADING'
export const RECEIVER_DATA = 'RECEIVER_DATA'
export const RECIEVER_ERROR = 'RECIEVER_ERROR'

// HOLIDAYS
export const HOLIDAYS = 'HOLIDAYS'
export const HOLIDAYS_LOADING = 'HOLIDAYS_LOADING'

// documents actions
export const FOLDERS_LOADING = 'FOLDERS_LOADING'
export const GET_FOLDERS = 'GET_FOLDERS'
export const FOLDERS_LOADED = 'FOLDERS_LOADED'
export const FOLDER_ERROR = 'FOLDER_ERROR'
export const HISTORICAL_DATA_LOADING = 'HISTORICAL_DATA_LOADING'
export const HISTORICAL_DATA_LOADED = 'HISTORICAL_DATA_LOADED'
export const HISTORICAL_DATA_ERROR = 'HISTORICAL_DATA_ERROR'

export const DOCUMENT_LOADING = 'DOCUMENT_LOADING'
export const DOCUMENT_LOADED = 'DOCUMENT_LOADED'
export const DOCUMENT_ADDED = 'DOCUMENT_ADDED'
export const DOCUMENT_ERROR = 'DOCUMENT_ERROR'
export const DOCUMENT_SHARING = 'DOCUMENT_SHARING'
export const DOCUMENT_SHARED = 'DOCUMENT_SHARED'

export const CREATING_FOLDER = 'CREATING_FOLDER'
export const FOLDER_CREATED = 'FOLDER_CREATED'
export const FOLDER_CREATE_ERROR = 'FOLDER_CREATE_ERROR'

export const FOLDER_DOWNLOADING = 'FOLDER_DOWNLOADING'
export const FOLDER_DOWNLOADED = 'FOLDER_DOWNLOADED'

export const INVOICE_PDF_CREATING = 'INVOICE_PDF_CREATING'
export const INVOICE_PDF_CREATED = 'INVOICE_PDF_CREATED'
export const INVOICE_PDF_CREATE_FAIL = 'INVOICE_PDF_CREATE_FAIL'
export const FOLDER_DELETING = 'FOLDER_DELETING'
export const FOLDER_DELETED = 'FOLDER_DELETED'

export const DOCUMENT_DELETING = 'DOCUMENT_DELETING'
export const DOCUMENT_DELETED = 'DOCUMENT_DELETED'
export const FILE_DOWNLOAD = 'FILE_DOWNLOAD'

// realtor / builder /property-manager actions
export const ADDING_REALTOR_CLIENT = 'ADDING_REALTOR_CLIENT'
export const ADDED_REALTOR_CLIENT = 'ADDED_REALTOR_CLIENT'
export const LOADING_CLIENTS = 'LOADING_CLIENTS'
export const LOADED_CLIENTS = 'LOADED_CLIENTS'
export const DOWNLOADING_CLIENTS = 'DOWNLOADING_CLIENTS'
export const DOWNLOADED_CLIENTS = 'DOWNLOADED_CLIENTS'
export const LOADING_WARRANTY_TEAM = 'LOADING_WARRANTY_TEAM'
export const LOADED_WARRANTY_TEAM = 'LOADED_WARRANTY_TEAM'
export const LOADING_WARRANTY_REQUESTS = 'LOADING_WARRANTY_REQUESTS'
export const LOADED_WARRANTY_REQUESTS = 'LOADED_WARRANTY_REQUESTS'
export const LOADING_CLIENT_STATUS = 'LOADING_CLIENT_STATUS'
export const LOADED_CLIENT_STATUS = 'LOADED_CLIENT_STATUS'
export const SAVING_CLIENT_STATUS = 'SAVING_CLIENT_STATUS'
export const SAVED_CLIENT_STATUS = 'SAVED_CLIENT_STATUS'
export const LOADING_TRANSACTION_TEAM = 'LOADING_TRANSACTION_TEAM'
export const LOADED_TRANSACTION_TEAM = 'LOADED_TRANSACTION_TEAM'
export const LOADING_EVALUATION_REQUESTS = 'LOADING_EVALUATION_REQUESTS'
export const LOADED_EVALUATION_REQUESTS = 'LOADED_EVALUATION_REQUESTS'
export const LOADING_LEADS = 'LOADING_LEADS'
export const LOADED_LEADS = 'LOADED_LEADS'
export const SAVING_LEAD = 'SAVING_LEAD'
export const SAVED_LEAD = 'SAVED_LEAD'
export const LOADING_STATS = 'LOADING_STATS'
export const LOADED_STATS = 'LOADED_STATS'
export const LOADING_PROFESSIONALS = 'LOADING_PROFESSIONALS'
export const LOADED_PROFESSIONALS = 'LOADED_PROFESSIONALS'
export const PAYMENT_CANCELLING = 'PAYMENT_CANCELLING'
export const PAYMENT_CANCELLED = 'PAYMENT_CANCELLED'
export const COUPON_APPLYING = 'COUPON_APPLYING'
export const COUPON_APPLIED = 'COUPON_APPLIED'
export const COUPON_ERROR = 'COUPON_ERROR'
export const LOADING_PENDING_INVITES = 'LOADING_PENDING_INVITES'
export const LOADED_PENDING_INVITES = 'LOADED_PENDING_INVITES'
export const LOADING_REFERRALS = 'LOADING_REFERRALS'
export const LOADED_REFERRALS = 'LOADED_REFERRALS'
export const SENDING_REFERRAL = 'SENDING_REFERRAL'
export const SENT_REFERRAL = 'SENT_REFERRAL'
export const SEND_REFERRAL_ERROR = 'SEND_REFERRAL_ERROR'
export const ADDING_BUYER_INTEREST = 'ADDING_BUYER_INTEREST'
export const BUYER_INTEREST_ADDED = 'BUYER_INTEREST_ADDED'
export const BUYER_INTEREST_LOADED = 'BUYER_INTEREST_LOADED'
export const BUYER_INTEREST_LOADING = 'BUYER_INTEREST_LOADING'
export const LOADING_CLIENT_PERMISSIONS = 'LOADING_CLIENT_PERMISSIONS'
export const LOADED_CLIENT_PERMISSIONS = 'LOADED_CLIENT_PERMISSIONS'
export const SAVING_CLIENT_PERMISSIONS = 'SAVING_CLIENT_PERMISSIONS'
export const SAVED_CLIENT_PERMISSIONS = 'SAVED_CLIENT_PERMISSIONS'
export const SAVING_CLIENT_PROPERTY = 'SAVING_CLIENT_PROPERTY'
export const SAVED_CLIENT_PROPERTY = 'SAVED_CLIENT_PROPERTY'
export const LOADING_CLIENT_LEASEAGREEMENT = 'LOADING_CLIENT_LEASEAGREEMENT'
export const LOADED_CLIENT_LEASEAGREEMENT = 'LOADED_CLIENT_LEASEAGREEMENT'
export const SAVING_CLIENT_LEASEAGREEMENT = 'SAVING_CLIENT_LEASEAGREEMENT'
export const SAVED_CLIENT_LEASEAGREEMENT = 'SAVED_CLIENT_LEASEAGREEMENT'
export const USER_PROFILE_VIEWED = 'USER_PROFILE_VIEWED'
export const USER_PROFILE_VIEW_SAVING = 'USER_PROFILE_VIEW_SAVING'
export const LOADING_CONTACTS = 'LOADING_CONTACTS'
export const LOADED_CONTACTS = 'LOADED_CONTACTS'
export const SAVING_CONTACTS = 'SAVING_CONTACTS'
export const SAVED_CONTACTS = 'SAVED_CONTACTS'
export const DOWNLOADING_CONTACTS = 'DOWNLOADING_CONTACTS'
export const DOWNLOADED_CONTACTS = 'DOWNLOADED_CONTACTS'

// user plan actions
export const LOADING_PLANS = 'LOADING_PLANS'
export const LOADED_PLANS = 'LOADED_PLANS'
export const LOADED_INVITE_PLANS = 'LOADED_INVITE_PLANS'
export const LOADED_GENERALSPONSORSHIP_PLANS = 'LOADED_GENERALSPONSORSHIP_PLANS'
export const SAVING_PLAN = 'SAVING_PLAN'
export const SAVED_PLAN = 'SAVED_PLAN'
export const SAVING_PLAN_FAILED = 'SAVING_PLAN_FAILED'
export const DELETING_PLAN = 'DELETING_PLAN'
export const DELETED_PLAN = 'DELETED_PLAN'
export const DELETING_PLAN_FAILED = 'DELETING_PLAN_FAILED'

// tips actions
export const TIPS_DETAILS = 'TIPS_DETAIL'
export const TIPS_LOADING = 'TIPS_LOADING'
export const TIP_DETAIL = 'TIP_DETAIL'
export const TIP_EMAILING = 'TIP_EMAILING'
export const TIP_EMAILED = 'TIP_EMAILED'

export const SPONSORS_DETAILS = 'SPONSORS_DETAIL'
export const SPONSORS_LOADING = 'SPONSORS_LOADING'
export const SPONSOR_DETAIL = 'SPONSOR_DETAIL'
export const SPONSOR_REMOVAL_REQUESTING = 'SPONSOR_REMOVAL_REQUESTING'
export const SPONSOR_REMOVAL_REQUESTED = 'SPONSOR_REMOVAL_REQUESTED'
export const GIFTS_LOADING = 'GIFTS_LOADING'
export const GIFTS_LOADED = 'GIFTS_LOADED'
export const GIFTS_LOAD_ERROR = 'GIFTS_LOAD_ERROR'

export const TEMPLATES_LOADING = 'TEMPLATES_LOADING'
export const TEMPLATES_LOADED = 'TEMPLATES_LOADED'
export const TEMPLATES_LOAD_ERROR = 'TEMPLATES_LOAD_ERROR'
export const TEMPLATE_SAVING = 'TEMPLATE_SAVING'
export const TERMPLATE_SAVED = 'TERMPLATE_SAVED'

// giveaway actions
export const GIVEAWAYS_LOADING = 'GIVEAWAYS_LOADING'
export const GIVEAWAYS_LOADED = 'GIVEAWAYS_LOADED'
export const GIVEAWAYS_LOAD_ERROR = 'GIVEAWAYS_LOAD_ERROR'
export const LOADING_EARNED_GIVEAWAYS = 'LOADING_EARNED_GIVEAWAYS'
export const LOADED_EARNED_GIVEAWAYS = 'LOADED_EARNED_GIVEAWAYS'

export const LOAD_GIFT_CART = 'LOAD_GIFT_CART'
export const CLEAR_GIFT_CART = 'CLEAR_GIFT_CART'
export const EMPTY_CART = 'EMPTY_CART'
export const ADDING_GIFT_CART = 'ADDING_GIFT_CART'
export const ADDING_CART = 'ADDING_CART'
export const REMOVING_GIFT_CART = 'REMOVING_GIFT_CART'
export const ADDING_PLAN_CART = 'ADDING_PLAN_CART'
export const REMOVING_PLAN_CART = 'REMOVING_PLAN_CART'
export const ADDING_LETTER_INFO = 'ADDING_LETTER_INFO'
export const ADDING_CLIENT_INFO = 'ADDING_CLIENT_INFO'
export const CONTRACT_DOCUMENTS_LOADING = 'CONTRACT_DOCUMENTS_LOADING'
export const CONTRACT_DOCUMENTS_LOADED = 'CONTRACT_DOCUMENTS_LOADED'
export const CONTARCT_DOCUMENT_SHARING = 'CONTARCT_DOCUMENT_SHARING'

// Payment actions
export const CHECKOUTING = 'CHECKOUTING'
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS'
export const CHECKOUT_ERROR = 'CHECKOUT_ERROR'
export const LOADING_PAYMETNS = 'LOADING_PAYMETNS'
export const LOADED_PAYMENTS = 'LOADED_PAYMENTS'
export const CARD_UPDATING = 'USER_CARD_UPDATING'
export const CARD_UPDATED = 'USER_CARD_UPDATED'
export const CARD_UPDATE_FAIL = 'USER_CARD_UPDATE_FAIL'
export const PAYMENT_DONE = 'PAYMENT_DONE'

// reviews
export const LOADING_REVIEW_REQUEST = 'LOADING_REVIEW_REQUEST'
export const LOADED_REVIEW_REQUEST = 'LOADED_REVIEW_REQUEST'
export const SUBMITTING_REVIEW = 'SUBMITTING_REVIEW'
export const SUBMITED_REVIEW = 'SUBMITED_REVIEW'
export const LOADING_REVIEWS = 'LOADING_REVIEWS'
export const LOADED_REVIEWS = 'LOADED_REVIEWS'
export const SAVING_REVIEW_TEMPLATE = 'SAVING_REVIEW_TEMPLATE'
export const SAVED_REVIEW_TEMPLATE = 'SAVED_REVIEW_TEMPLATE'
export const REQUESTING_REVIEW = 'REQUESTING_REVIEW'
export const REQUESTED_REVIEW = 'REQUESTED_REVIEW'

// Replacement Budget actions
export const GET_FILTER_ITEMS = 'GET_FILTER_ITEMS'
export const FILTER_ITEMS = 'FILTER_ITEMS'
export const REPLACEMENT_BUDGET_SEARCHING = 'REPLACEMENT_BUDGET_SEARCHING'
export const REPLACEMENT_BUDGET_SEARCHED = 'REPLACEMENT_BUDGET_SEARCHED'
export const REPLACEMENT_BUDGET_LOADING = 'REPLACEMENT_BUDGET_LOADING'
export const REPLACEMENT_BUDGET_LOADED = 'REPLACEMENT_BUDGET_LOADED'
export const REPLACEMENT_BUDGET_ADDING = 'REPLACEMENT_BUDGET_ADDING'
export const REPLACEMENT_BUDGET_ADDED = 'REPLACEMENT_BUDGET_ADDED'
export const GET_APPLIANCES_EQUIPMENTS = 'GET_APPLIANCES_EQUIPMENTS'

// general notes actions
export const LOADING_NOTES = 'LOADING_NOTES'
export const LOADED_NOTES = 'LOADED_NOTES'
export const SAVING_NOTE = 'SAVING_NOTE'
export const SAVED_NOTE = 'SAVED_NOTE'
export const DELETING_NOTE = 'DELETING_NOTE'
export const DELETED_NOTE = 'DELETED_NOTE'
export const UPDATED_NOTE = 'UPDATED_NOTE'

// report actions
export const VERIFYING_REPORT_LINK = 'VERIFYING_REPORT_LINK'
export const VERIFYED_REPORT_LINK = 'VERIFYED_REPORT_LINK'
export const REPORT_VERIFICATION_FAILED = 'REPORT_VERIFICATION_FAILED'
export const LOADING_REPORT_DATA = 'LOADING_REPORT_DATA'
export const LOADED_REPORT_DATA = 'LOADED_REPORT_DATA'

// message actions
export const UPDATING_MESSAGE_ACTION = 'UPDATING_MESSAGE_ACTION'
export const UPDATED_MESSAGE_ACTION = 'UPDATED_MESSAGE_ACTION'

// general sponsorship action
export const LOADING_SPONSORSHIP = 'LOADING_SPONSORSHIP'
export const LOADED_SPONSORSHIP = 'LOADED_SPONSORSHIP'
export const SAVING_SPONSORSHIP = 'SAVING_SPONSORSHIP'
export const SAVED_SPONSORSHIP = 'SAVED_SPONSORSHIP'
