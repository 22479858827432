import {
  LOADING_PLANS,
  LOADED_PLANS,
  SAVING_PLAN,
  SAVED_PLAN,
  LOADED_INVITE_PLANS,
  LOADED_GENERALSPONSORSHIP_PLANS,
  // SAVING_PLAN_FAILED,
  // DELETING_PLAN,
  // DELETED_PLAN,
  // DELETING_PLAN_FAILED,
} from '../actions/types'

const initialState = {
  isLoading: false,
  isSaving: false,
  homeowner: null,
  builder: null,
  realtor: null,
  client_sponsor: null,
  client_branding: null,
  purchaseClientInvitePlan: [],
  generalSponsorshipPlan: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_PLANS:
      return {
        ...state,
        isLoading: true,
      }
    case LOADED_INVITE_PLANS:
      return {
        ...state,
        purchaseClientInvitePlan: action.payload,
      }
    case LOADED_GENERALSPONSORSHIP_PLANS:
      return {
        ...state,
        generalSponsorshipPlan: action.payload,
      }
    case LOADED_PLANS:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      }
    case SAVING_PLAN:
      return {
        ...state,
        isSaving: true,
      }
    case SAVED_PLAN:
      return {
        ...state,
        isSaving: false,
        ...action.payload,
      }
    default:
      return state
  }
}
