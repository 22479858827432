import {
  LOADING_REVIEW_REQUEST,
  LOADED_REVIEW_REQUEST,
  SUBMITTING_REVIEW,
  SUBMITED_REVIEW,
  LOADING_REVIEWS,
  LOADED_REVIEWS,
  SAVING_REVIEW_TEMPLATE,
  SAVED_REVIEW_TEMPLATE,
  REQUESTING_REVIEW,
  REQUESTED_REVIEW,
  LOGOUT_SUCCESS,
  AUTH_ERROR,
} from '../actions/types'

const initialState = {
  isLoading: false,
  isSaving: false,
  review: null,
  data: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_REVIEWS:
    case LOADING_REVIEW_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case LOADED_REVIEWS:
    case LOADED_REVIEW_REQUEST:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      }
    case SUBMITTING_REVIEW:
    case SAVING_REVIEW_TEMPLATE:
      return {
        ...state,
        isSaving: true,
      }
    case SUBMITED_REVIEW:
    case SAVED_REVIEW_TEMPLATE:
      return {
        ...state,
        ...action.payload,
        isSaving: false,
      }
    case REQUESTING_REVIEW:
      return {
        ...state,
        isSaving: true,
      }
    case REQUESTED_REVIEW:
      return {
        ...state,
        isSaving: true,
      }
    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
      window.localStorage.removeItem('token')
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}
