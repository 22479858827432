import {
  LOADING_SPONSORSHIP,
  LOADED_SPONSORSHIP,
  SAVING_SPONSORSHIP,
  SAVED_SPONSORSHIP,
} from '../actions/types'

const initialState = {
  isLoading: false,
  isSaving: false,
  data: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_SPONSORSHIP:
      return {
        ...state,
        isLoading: true,
      }
    case LOADED_SPONSORSHIP:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      }
    case SAVING_SPONSORSHIP:
      return {
        ...state,
        isSaving: true,
      }
    case SAVED_SPONSORSHIP:
      return {
        ...state,
        isSaving: false,
        ...action.payload,
      }
    default:
      return state
  }
}
