/* eslint-disable */
import axios from 'axios'
import { config } from './config'
const baseurl = process.env.REACT_APP_SERVER_URL

export const logError = req => {
  axios.post(baseurl + '/api/errors/submit', JSON.stringify(req), {
    headers: {
      'Content-type': 'application/json',
    },
  })

  return true
}

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / k ** i).toFixed(dm)) + ' ' + sizes[i]
}

export const formatDocumentFileName = (fileName, addExt = true) => {
  const fileNameArr = fileName.split('.')
  let newFileName
  if (addExt) {
    newFileName = `${fileNameArr[0].substring(0, 15)}.${fileNameArr[fileNameArr.length - 1]}`
  } else {
    newFileName = `${fileNameArr[0].substring(0, 15)}`
  }
  if (fileNameArr[0].length > 15) {
    newFileName += '...'
  }
  return newFileName
}

export const formatPhoneNumber = phoneNumberString => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return null
}

export const checkIsMobile = () => {
  const { userAgent } = window.navigator
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone|IEMobile|Opera Mini/i.test(
    userAgent,
  )
  // console.log({ isMobile })
  return isMobile
}

// HomeValue increase, Savings calculation
export const maintenanceMonthlyDetails = ({
  estate_data,
  tasks_data,
  tasks_status,
  task_select,
}) => {
  let fairMarketValue = 0
  if (estate_data) {
    if (
      estate_data.property_update &&
      estate_data.property_update.market_value &&
      !isNaN(estate_data.property_update.market_value)
    )
      fairMarketValue = parseInt(estate_data.property_update.market_value)
    else if (
      estate_data.details &&
      estate_data.details.market_assessments &&
      estate_data.details.market_assessments.length
    )
      fairMarketValue = parseInt(estate_data.details.market_assessments[0].total_value)
  }
  let homeValue = 0,
    homeValue_TasksCompleted = {},
    homeValue_TasksCount = {},
    month_saving_amount = 0,
    month_saving_potential = 0,
    safety_alerts = 0,
    health_alerts = 0,
    preventive_maintenance_alerts = 0,
    energy_efficiency_alerts = 0,
    insurance_alerts = 0,
    total_TaskCompleted = 0,
    total_Tasks = 0

  if (typeof tasks_data != 'undefined' && tasks_data != null && tasks_data.length > 0) {
    const statusCheck = Object.keys(tasks_status).length
    tasks_data.map((t, i) => {
      if (t.safety) safety_alerts += 1
      if (t.health) health_alerts += 1
      if (t.preventive_maintenance) preventive_maintenance_alerts += 1
      if (t.energy_efficiency) energy_efficiency_alerts += 1
      if (t.insurance_effect) insurance_alerts += 1

      if (
        !statusCheck ||
        (tasks_status &&
          tasks_status[t._id] &&
          !t.custom_task &&
          (tasks_status[t._id].task_status === 0 || tasks_status[t._id].task_status === 1))
      ) {
        const ts = tasks_status[t._id]
        const tm = new Date(ts ? ts.task_date : t.task_date).getMonth()
        if (typeof homeValue_TasksCount[tm] == 'undefined') {
          homeValue_TasksCount[tm] = 0
          homeValue_TasksCompleted[tm] = 0
        }
        if (t.increase_homevalue) homeValue_TasksCount[tm] = homeValue_TasksCount[tm] + 1
        total_Tasks = total_Tasks + 1

        if (t.saving_amount > 0) {
          month_saving_potential = month_saving_potential + parseFloat(t.saving_amount)
        }

        if (ts && ts.task_status === 1) {
          total_TaskCompleted = total_TaskCompleted + 1
          if (t.increase_homevalue) homeValue_TasksCompleted[tm] = homeValue_TasksCompleted[tm] + 1
          if (t.saving_amount > 0) {
            month_saving_amount = month_saving_amount + parseFloat(t.saving_amount)
          }
          if (ts.task_completed && ts.task_completed.cost_self > 0) {
            month_saving_amount = month_saving_amount - parseFloat(ts.task_completed.cost_self)
          }
          if (ts.task_completed && ts.task_completed.cost_professional > 0) {
            month_saving_amount =
              month_saving_amount - parseFloat(ts.task_completed.cost_professional)
          }
        }
      }
    })
  } else if (task_select && tasks_status && Object.keys(tasks_status).length === 0) {
    task_select.map(task => {
      task.subtasks.map(subtask => {
        // if (task.safety) safety_alerts += 1
        // if (task.health) health_alerts += 1
        // if (task.preventive_maintenance) preventive_maintenance_alerts += 1
        // if (task.energy_efficiency) energy_efficiency_alerts += 1
        // if (task.insurance_effect) insurance_alerts += 1
        if (subtask.saving_amount > 0) {
          month_saving_potential = month_saving_potential + parseFloat(subtask.saving_amount)
        }
      })
    })
  }

  // console.log(homeValue_TasksCompleted, homeValue_TasksCount)
  homeValue_TasksCount = Object.values(homeValue_TasksCount)
  homeValue_TasksCompleted = Object.values(homeValue_TasksCompleted)
  homeValue_TasksCount.map((c, i) => {
    if (homeValue_TasksCompleted[i] > 0 && c > 0)
      homeValue += ((fairMarketValue * 0.01) / 12) * (homeValue_TasksCompleted[i] / c)
  })

  if (!(homeValue >= 0)) homeValue = 0
  else homeValue = window.roundtoPlaces(homeValue, 0)
  if (month_saving_amount <= 0) month_saving_amount = 0
  else month_saving_amount = window.roundtoPlaces(month_saving_amount, 2)
  if (month_saving_potential <= 0) month_saving_potential = 0
  else month_saving_potential = window.roundtoPlaces(month_saving_potential, 2)
  // console.log(total_Tasks, total_TaskCompleted)

  return {
    homeValue,
    month_saving_amount,
    month_saving_potential,
    safety_alerts,
    health_alerts,
    preventive_maintenance_alerts,
    energy_efficiency_alerts,
    insurance_alerts,
    total_Tasks,
    total_TaskCompleted,
  }
}
export const maintenanceCardDetails = (
  { estate_data, tasks_data, tasks_status, task_select },
  mode,
) => {
  if (mode === 'month')
    return maintenanceMonthlyDetails({ estate_data, tasks_data, tasks_status, task_select })
  else {
    const monthTaskData = {}
    tasks_data.map(td => {
      const task_date = new Date(
        typeof tasks_status[td._id] != 'undefined' ? tasks_status[td._id].task_date : td.task_date,
      )
      if (typeof monthTaskData[task_date.getMonth()] == 'undefined')
        monthTaskData[task_date.getMonth()] = []
      monthTaskData[task_date.getMonth()].push(td)
    })
    let hv = 0,
      msa = 0,
      msp = 0,
      sa = 0,
      ha = 0,
      pma = 0,
      eea = 0,
      ia = 0,
      tt = 0,
      ttc = 0
    for (let i in monthTaskData) {
      const {
        homeValue,
        month_saving_amount,
        month_saving_potential,
        safety_alerts,
        health_alerts,
        preventive_maintenance_alerts,
        energy_efficiency_alerts,
        insurance_alerts,
        total_Tasks,
        total_TaskCompleted,
      } = maintenanceMonthlyDetails({ estate_data, tasks_data: monthTaskData[i], tasks_status })
      // console.log({ i, month_saving_amount, homeValue })
      hv += homeValue
      msa += month_saving_amount
      msp += month_saving_potential
      sa += safety_alerts
      ha += health_alerts
      pma += preventive_maintenance_alerts
      eea += energy_efficiency_alerts
      ia += insurance_alerts
      tt += total_Tasks
      ttc += total_TaskCompleted
    }
    return {
      homeValue: hv,
      month_saving_amount: msa,
      month_saving_potential: msp,
      safety_alerts: sa,
      health_alerts: ha,
      preventive_maintenance_alerts: pma,
      energy_efficiency_alerts: eea,
      insurance_alerts: ia,
      total_Tasks: tt,
      total_TaskCompleted: ttc,
    }
  }
}

// HomeScore calculation
// export const hf_colors = ["#D5556B", "#F5A81E", "#26b92d"];
export const hf_colors = ['#BD2427', '#F89A38', '#FBDE06', '#8EC341', '#0F9D4B']
export const options_hfScore = {
  cutoutPercentage: 20,
  rotation: Math.PI, // 0.7 *
  circumference: Math.PI, // 1.6 *
  responsive: true,
  tooltips: {
    callbacks: {
      title: function(tooltipItem, data) {
        // console.log()
        return data['labels'][tooltipItem[0]['index']]
      },
      label: function(tooltipItem, data) {
        let labelText = ''
        switch (tooltipItem['index']) {
          case 0:
            labelText = '0-20'
            break
          case 1:
            labelText = '>20'
            break
          case 2:
            labelText = '>40'
            break
          case 3:
            labelText = '>60'
            break
          case 4:
            labelText = '>80'
            break
        }
        return (labelText += '%')
        // return data['datasets'][0]['data'][tooltipItem['index']] * (tooltipItem['index'] + 1) + '%'
      },
      // afterLabel: function(tooltipItem, data) {
      //   var dataset = data['datasets'][0];
      //   var percent = Math.round((dataset['data'][tooltipItem['index']] / dataset["_meta"][0]['total']) * 100)
      //   return '(' + percent + '%)';
      // }
    },
  },
  legend: {
    position: 'bottom',
    display: true,
    labels: {
      boxWidth: 10,
      padding: 15,
      fontColor: '#7d7d7d',
      fontSize: 12,
      pointStyle: 'circle',
      usePointStyle: true,
    },
  },
}
export const data_hfScore = {
  // labels: ["Not Maintained", "Partially Maintained", "Well Maintained"],
  labels: ['Poor', 'Fair', 'Average', 'Good', 'Great'],
  datasets: [
    {
      backgroundColor: hf_colors,
      borderWidth: 2,
      data: [20, 20, 20, 20, 20],
    },
  ],
}
export const getHomeScore = (tasks, status) => {
  // console.log(tasks, status)
  let tasksCompleted = 0,
    yearTasks = 0
  tasks.map(t => {
    if (!t.custom_task && t.apply_homescore) {
      if (status && status[t._id] && status[t._id].task_status >= 0) {
        yearTasks++
        if (status[t._id].task_status === 1) tasksCompleted++
      }
    }
  })
  // console.log({ tasksCompleted, yearTasks })
  // [min1, max1] to [min2, max2] == [0, tasksThisMonth] to [-50, 230]
  // min2 + ((value - min1) / (max1 - min1)) * (max2 - min2);
  let score = 0 + ((tasksCompleted - 0) / (yearTasks - 0)) * (180 - 0)
  score = window.roundtoPlaces(score, 0)
  if (isNaN(score)) score = 0
  let score100 = 0 + ((tasksCompleted - 0) / (yearTasks - 0)) * (100 - 0)
  score100 = window.roundtoPlaces(score100, 0)
  // score100 = 21
  // score = (score100 * 180) / 100
  // console.log({ score, score100 })
  return { score, percentage: score100 }
}

export const saveCartInfo = cart => {
  window.localStorage.setItem('cartInfo', JSON.stringify(cart))
}

export const getCartInfo = () => {
  return JSON.parse(window.localStorage.getItem('cartInfo'))
}

export const removeCartInfo = () => {
  window.localStorage.removeItem('cartInfo')
}

export const saveClientInfo = ({ clientId, propertyId }) => {
  const cart = JSON.parse(window.localStorage.getItem('cartInfo'))
  if (!cart) return
  cart.clientInfo = { propertyId, clientId }
  // console.log({ cart })
  window.localStorage.setItem('cartInfo', JSON.stringify(cart))
}

export const propertyCookies = (id, optionsName, value) => {
  if (id) {
    const cookieName = 'previews-' + id.toString()
    let cookies = window.getCookie(cookieName)
    cookies = !cookies ? {} : JSON.parse(cookies)
    if (typeof value != 'undefined') {
      cookies[optionsName] = value ? value : false
      window.setCookie(cookieName, JSON.stringify(cookies), 720)
    }
    return cookies[optionsName] ? cookies[optionsName] : null
  }
}

export const maintenanceCostEstimate = structure => {
  const averageHomeSize = 2100, // SF
    moreThanAvegFactor = 0.28
  let maintenance_estimation = 7310 // 5394
  if (structure && structure && structure.total_area_sq_ft > averageHomeSize) {
    maintenance_estimation += moreThanAvegFactor * (structure.total_area_sq_ft - averageHomeSize)
  }
  // console.log(maintenance_estimation)
  return window.roundtoPlaces(maintenance_estimation, 2)
}

export const getAddressString = obj => {
  // console.log(obj)
  let addressString = '-'
  if (obj) {
    if (obj.address) addressString = obj.address
    else if (obj.street) addressString = obj.street
    else if (obj.formatted_street_address) addressString = obj.formatted_street_address
    addressString += `, ${obj.city}, ${
      config.us_states[obj.state] ? config.us_states[obj.state] : obj.state
    } ${obj.zip_code}`
  }
  // console.log(addressString)
  return addressString
}

export const processAddress = place => {
  // console.log(place)
  const components = { address: '', city: '', state: '', zip_code: '', country: '' }
  let neighborhood = ''
  place.address_components.map((c, j) => {
    if (c.types.indexOf('neighborhood') !== -1) {
      neighborhood = c.short_name
    }
    if (c.types.includes('street_number')) {
      components.address += c.long_name + ' '
    }
    if (c.types.includes('route')) {
      components.address += c.long_name
    }
    if (c.types.includes('locality') || c.types.includes('sublocality')) {
      components.city = c.short_name
    }
    if (c.types.includes('administrative_area_level_1')) {
      components.state = c.short_name
    }
    if (c.types.includes('country')) {
      components.country = c.short_name
    }
    if (c.types.includes('postal_code')) {
      components.zip_code = c.long_name
    }
  })
  if (neighborhood) components.address = `${components.address} ${neighborhood}`

  if (place.geometry) {
    components.latitude = place.geometry.location.lat()
    components.longitude = place.geometry.location.lng()
  }

  return { str: place.formatted_address, obj: components }
}

export const onBoardingStep = ({ user, estate_data, home_team }) => {
  let incomplete_step_url = '#',
    incomplete_steps = []
  if (user) {
    if (
      typeof user.calendars == 'undefined' ||
      (typeof user.calendars.google == 'undefined' && typeof user.calendars.outlook == 'undefined')
    ) {
      incomplete_step_url = '/register-calendars'
      incomplete_steps.push(5)
    }
    if (home_team === null || (home_team && home_team.length === 0)) {
      incomplete_step_url = '/register-hometeam'
      incomplete_steps.push(4)
    }
  }
  if (estate_data && typeof estate_data.details !== 'undefined') {
    const {
      details: { market_assessments, market_value, market_value_date, inventory, floorplan },
    } = estate_data
    if (typeof floorplan == 'undefined' || floorplan.length === 0) {
      incomplete_step_url = '/homeowner/emergency-floor-plan'
      incomplete_steps.push(6)
    }
    if (typeof inventory == 'undefined' || inventory.length === 0) {
      incomplete_step_url = '/register-inventory-interior'
      incomplete_steps.push(2)
      incomplete_steps.push(3)
    } else if (inventory.length) {
      if (
        inventory.filter(data => {
          return data.type === 'interior'
        }).length === 0
      ) {
        incomplete_step_url = '/register-inventory-interior'
        incomplete_steps.push(2)
      } else if (
        inventory.filter(data => {
          return data.type === 'exterior'
        }).length === 0
      ) {
        incomplete_step_url = '/register-inventory-interior'
        incomplete_steps.push(3)
      }
    }
    if (
      !market_assessments ||
      !market_assessments[0] ||
      !market_assessments[0].total_value ||
      !market_value ||
      !market_value_date
    ) {
      incomplete_step_url = '/financial-history'
      incomplete_steps.push(1)
    }
  }
  const complete_progress = Math.floor((6 - incomplete_steps.length) * 16.67)
  // console.log(incomplete_step_url, complete_progress)
  return { incomplete_step_url, complete_progress, incomplete_steps }
}

export const calcHomeEquity = (details, savings_data) => {
  // console.log(details, savings_data)
  if (!details || !savings_data) return {}
  const dateNow = new Date()
  let homeEquity = 0,
    avgHomeValue = 0,
    homeLoanValue = 0,
    homeValueTotal = 0
  if (details.experts_value && details.tax_assessed_value) {
    avgHomeValue += parseFloat(details.experts_value)
    avgHomeValue += parseFloat(details.tax_assessed_value)
    let divider = 2
    if (details.appraised_value) {
      avgHomeValue += parseFloat(details.appraised_value)
      divider += 1
    }
    if (details.realtors_value) {
      avgHomeValue += parseFloat(details.realtors_value)
      divider += 1
    }
    avgHomeValue = parseInt(avgHomeValue / divider)
  }
  if (
    details.loan_value &&
    details.loan_term &&
    details.loan_intrate &&
    details.loan_firstpayment_date
  ) {
    const P = parseFloat(details.loan_value),
      T = parseInt(details.loan_term) * 12,
      R = parseFloat(details.loan_intrate) / 100
    const monthR = R / 12
    const powValue = (1 + monthR) ** T
    const monthlyEMI = P * monthR * (powValue / (powValue - 1))
    homeLoanValue = parseFloat(details.loan_value)
    // homeLoanValue = monthlyEMI * T,
    let monthsPaidFor = 0

    const dateFirstPay = new Date(details.loan_firstpayment_date)
    let dateLastPay = new Date(dateFirstPay.getTime())
    dateLastPay.setFullYear(dateFirstPay.getFullYear() + parseInt(details.loan_term))
    if (dateLastPay.getTime() > dateNow.getTime()) dateLastPay = dateNow
    for (let y = dateFirstPay.getFullYear(); y <= dateLastPay.getFullYear(); y++) {
      let m = y === dateFirstPay.getFullYear() ? dateFirstPay.getMonth() : 0
      for (m; m <= 11; m++) {
        let dateLoop = new Date(y, m, dateFirstPay.getDate())
        if (
          dateLoop.getFullYear() < dateLastPay.getFullYear() ||
          dateLoop.getMonth() <= dateLastPay.getMonth()
        ) {
          monthsPaidFor += 1
          homeLoanValue -= monthlyEMI - homeLoanValue * monthR
        }
      }
    }
    // homeLoanValue = monthlyEMI * (T - monthsPaidFor)
    homeLoanValue = parseInt(homeLoanValue)
    // console.log({ monthlyEMI, monthsPaidFor, monthR, homeLoanValue })

    for (let i in savings_data.user_data) {
      homeValueTotal += savings_data.user_data[i]
    }
    homeEquity = Math.max(0, avgHomeValue - homeLoanValue + homeValueTotal)
  }
  return { homeEquity, avgHomeValue, homeLoanValue, homeValueTotal }
}
