import {
  VERIFYING_REPORT_LINK,
  VERIFYED_REPORT_LINK,
  REPORT_VERIFICATION_FAILED,
  LOADING_REPORT_DATA,
  LOADED_REPORT_DATA,
} from '../actions/types'

const initialState = {
  isVerifying: false,
  secret: null,
  isLoading: false,
  user: null,
  home_team: null,
  estate_data: null,
  earnedGiveaway: null,
  giveAways: null,
  tasks_data: null,
  tasks_status: null,
  tasks_data_year: null,
  tasks_status_year: null,
  alluser_tasks: null,
  savings_data: null,
  userFolders: null,
  sponsorsData: null,
  investment: null,
  budget: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case VERIFYING_REPORT_LINK:
      return {
        ...state,
        isVerifying: true,
      }
    case VERIFYED_REPORT_LINK:
      return {
        ...state,
        ...action.payload,
        isVerifying: false,
      }
    case LOADING_REPORT_DATA:
      return {
        ...state,
        isLoading: true,
      }
    case LOADED_REPORT_DATA:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      }
    default:
      return state
  }
}
