import { GETTING_ADMINSETTING, GET_ADMINSETTING } from '../actions/types'

const initialState = {
  data: null,
  loading: false,
  version: {},
  intercom: {},
}

export default function(state = initialState, action) {
  switch (action.type) {
    case GETTING_ADMINSETTING:
      return {
        ...state,
        loading: true,
      }
    case GET_ADMINSETTING:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    default:
      return state
  }
}
