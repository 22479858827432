import {
  CHECKOUTING,
  CHECKOUT_SUCCESS,
  CHECKOUT_ERROR,
  LOADING_PAYMETNS,
  LOADED_PAYMENTS,
  AUTH_ERROR,
  LOGOUT_SUCCESS,
  CARD_UPDATED,
  CARD_UPDATING,
  CARD_UPDATE_FAIL,
  PAYMENT_DONE,
  COUPON_APPLYING,
  COUPON_APPLIED,
  COUPON_ERROR,
  INVOICE_PDF_CREATING,
  INVOICE_PDF_CREATED,
} from '../actions/types'

const initialState = {
  loading: false,
  invoicePdfLoading: {},
  error: null,
  details: {},
  data: null,
  paymentId: null,
  cardUpdateLoading: false,
  cardUpdateError: null,
  couponAmount: 0,
  couponType: null,
  couponKey: null,
  couponCode: null,
  generalSponsorshipId: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case CHECKOUTING:
    case LOADING_PAYMETNS:
      return {
        ...state,
        loading: true,
      }
    case CHECKOUT_SUCCESS:
      return {
        ...state,
        paymentId: action.payload.paymentId,
        details: action.payload.msg,
        error: null,
        loading: false,
      }
    case LOADED_PAYMENTS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    case INVOICE_PDF_CREATING:
      return {
        ...state,
        invoicePdfLoading: { [action.payload.paymentId]: true },
      }
    case INVOICE_PDF_CREATED:
      return {
        ...state,
        invoicePdfLoading: {},
      }
    case CHECKOUT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
      window.localStorage.removeItem('token')
      return {
        ...state,
        ...initialState,
      }
    case CARD_UPDATED:
    case CARD_UPDATING:
      return {
        ...state,
        cardUpdateLoading: !state.cardUpdateLoading,
      }
    case CARD_UPDATE_FAIL:
      return {
        ...state,
        cardUpdateError: action.payload,
        cardUpdateLoading: false,
      }
    case PAYMENT_DONE:
      return {
        ...state,
        details: [],
        couponAmount: 0,
        couponType: null,
        couponKey: null,
      }
    case COUPON_APPLYING:
    case COUPON_ERROR:
      return {
        ...state,
        couponAmount: 0,
        couponType: null,
      }
    case COUPON_APPLIED:
      return {
        ...state,
        couponAmount: action.payload.couponAmount,
        couponKey: action.payload.couponKey,
        couponCode: action.payload.couponCode,
        couponType: action.payload.couponType,
      }

    default:
      return state
  }
}
