import {
  LOADED_AUTHCONFIG,
  USER_LOADING,
  USER_LOADED,
  USER_PROFILE_UPDATING,
  USER_PROFILE_UPDATED,
  USER_PROFILE_UPDATE_FAIL,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
} from '../actions/types'

const initialState = {
  token: window.localStorage.getItem('token'),
  isAuthenticated: null,
  isLoading: false,
  user: null,
  OAuthDetails: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADED_AUTHCONFIG:
      return {
        ...state,
        ...action.payload,
      }
    case USER_LOADING:
    case USER_PROFILE_UPDATING:
      return {
        ...state,
        isLoading: true,
      }
    case USER_PROFILE_UPDATED:
    case USER_PROFILE_UPDATE_FAIL:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        ...action.payload,
      }
    case USER_LOADED:
    case LOGIN_SUCCESS:
      window.localStorage.setItem('token', action.payload.token)
      window.localStorage.setItem('user_id', action.payload.user._id)
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false,
      }
    case REGISTER_SUCCESS:
      // window.localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        ...action.payload,
        // isAuthenticated: false,
        isLoading: false,
      }
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT_SUCCESS:
    case REGISTER_FAIL:
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('user_id')
      return {
        ...state,
        token: null,
        user: null,
        OAuthDetails: null,
        isAuthenticated: null,
        isLoading: false,
      }
    default:
      return state
  }
}
