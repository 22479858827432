import {
	TIPS_DETAILS,
	TIPS_LOADING,
	TIP_DETAIL,
	TIP_EMAILING,
	TIP_EMAILED,
} from '../actions/types';

const initialState = {
	data: null,
	isLoading: false,
	singleData: null
}

export default function (state = initialState, action) {
	switch (action.type) {

		case TIPS_DETAILS:
		case TIP_DETAIL:
		case TIP_EMAILING:
		case TIP_EMAILED:
			return {
				...state,
				isLoading: false,
				...action.payload
			};
		case TIPS_LOADING:
			return {
				...state,
				isLoading: true,
			};
		default:
			return state;

	}
}