import {
  AUTH_ERROR,
  LOGOUT_SUCCESS,
  TASKS_LOADING,
  TASKS_LOADED,
  YEAR_TASKS_LOADING,
  YEAR_TASKS_LOADED,
  ALL_USER_TASKS_LOADING,
  ALL_USER_TASKS_LOADED,
  ADMIN_TASKS_LOADING,
  ADMIN_TASKS_LOADED,
  SAVINGS_LOADING,
  SAVINGS_LOADED,
  TASK_STAUS_UPDATING,
  TASK_STAUS_UPDATED,
  TASK_SELECTION_UPDATING,
  TASK_SELECTION_UPDATED,
  TASK_CUSTOM_ADDING,
  TASK_CUSTOM_ADDED,
  TASK_CUSTOM_DELETING,
  TASK_CUSTOM_DELETED,
  TASKS_ERROR,
} from '../actions/types'

const initialState = {
  loadingTasks: false,
  saving: false,
  msg: '',
  data: null,
  status: null,
  task_select: [],
  homeScore: null,
  homeValue: null,
  savings: null,
  task_alerts: null,
  link_id: null,
  toolbagFileLink: null,
  loadingYearTasks: false,
  year_data: null,
  year_status: null,
  year_homeScore: null,
  year_homeValue: null,
  year_savings: null,
  year_task_alerts: null,
  loadingAllUserTasks: false,
  alluser_data: null,
  loadingAdminTasks: false,
  admin_data: null,
  loadingSavings: false,
  savings_data: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case TASKS_LOADING:
      return {
        ...state,
        loadingTasks: true,
      }
    case TASKS_LOADED:
      return {
        ...state,
        ...action.payload,
        loadingTasks: false,
      }
    case YEAR_TASKS_LOADING:
      return {
        ...state,
        loadingYearTasks: true,
      }
    case YEAR_TASKS_LOADED:
      return {
        ...state,
        ...action.payload,
        loadingYearTasks: false,
      }
    case ALL_USER_TASKS_LOADING:
      return {
        ...state,
        loadingAllUserTasks: true,
      }
    case ALL_USER_TASKS_LOADED:
      return {
        ...state,
        ...action.payload,
        loadingAllUserTasks: false,
      }
    case ADMIN_TASKS_LOADING:
      return {
        ...state,
        loadingAdminTasks: true,
      }
    case ADMIN_TASKS_LOADED:
      return {
        ...state,
        loadingAdminTasks: false,
        ...action.payload,
      }
    case SAVINGS_LOADING:
      return {
        ...state,
        ...action.payload,
        loadingSavings: true,
      }
    case SAVINGS_LOADED:
      return {
        ...state,
        ...action.payload,
        loadingSavings: false,
      }
    case TASK_STAUS_UPDATING:
    case TASK_SELECTION_UPDATING:
    case TASK_CUSTOM_ADDING:
    case TASK_CUSTOM_DELETING:
      return {
        ...state,
        saving: true,
      }
    case TASK_STAUS_UPDATED:
      let status_update = { ...state.status }
      // data_update = { ...state.data }
      status_update[action.payload.status['subtask_id']] = action.payload.status
      return {
        ...state,
        status: status_update,
        msg: action.payload.msg,
        saving: false,
      }
    case TASK_SELECTION_UPDATED:
    case TASK_CUSTOM_DELETED:
      return {
        ...state,
        saving: false,
      }
    case TASK_CUSTOM_ADDED:
      let status_new = { ...state.status },
        data_new = [...state.data]
      let newStatus = action.payload.status,
        newSubtask = action.payload.data
      if (!data_new || !data_new.length) data_new = []
      data_new.push(newSubtask)
      status_new[newSubtask._id] = newStatus
      // console.log(data, status)
      return {
        ...state,
        data: data_new,
        status: status_new,
        saving: false,
      }
    case TASKS_ERROR:
      return {
        ...state,
        ...action.payload,
        saving: false,
        loadingTasks: false,
      }
    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
      window.localStorage.removeItem('token')
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}
