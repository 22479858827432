import {
  GIVEAWAYS_LOADING,
  GIVEAWAYS_LOADED,
  GIVEAWAYS_LOAD_ERROR,
  LOADING_EARNED_GIVEAWAYS,
  LOADED_EARNED_GIVEAWAYS,
  AUTH_ERROR,
  LOGOUT_SUCCESS,
} from '../actions/types'

const initialState = {
  loading: false,
  data: null,
  loadingEarned: false,
  earned: null,
  error: [],
}

export default function(state = initialState, action) {
  switch (action.type) {
    case GIVEAWAYS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case LOADING_EARNED_GIVEAWAYS:
      return {
        ...state,
        loadingEarned: true,
      }
    case LOADED_EARNED_GIVEAWAYS:
      return {
        ...state,
        loadingEarned: false,
        ...action.payload,
      }
    case GIVEAWAYS_LOADED:
      return {
        ...state,
        loading: false,
        ...action.payload,
      }
    case LOADING_EARNED_GIVEAWAYS:
      return {
        ...state,
        loadingEarned: true,
      }
    case LOADED_EARNED_GIVEAWAYS:
      return {
        ...state,
        loadingEarned: false,
        ...action.payload,
      }
    case GIVEAWAYS_LOAD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
      window.localStorage.removeItem('token')
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}
