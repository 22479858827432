import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import store from './store'
import jwt from 'jsonwebtoken'
import { NotificationManager } from 'react-notifications'

// import useForm from './hooks/useForm'
import usePrev from './hooks/usePrev'
import { loadUser, logout } from './actions/authActions'
import { getAdminsetting } from './actions/adminsettingActions'
import { config } from './config'

export const noAuthAllowedPaths = new RegExp(
  /(\/register\/(homeowner|builder|realtor|professional|login|logout|success)|\/login|\/logout)/,
)
const allAllowedPaths = new RegExp(
  /(\/(#register)*$|\/search|verify-email\/+|hometeam-status\/+|reset-password\/+|forgot-password|homeowner-report|general-sponsorship|address-search|unsubscribe|register-homeinformation|homeowner\/maintenance|homeowner\/team|get-review)/,
)

let logoutMsg = false

// const initialState = {
//   logoutMsg: false,
// }

const Authorize = props => {
  const reduxDispatch = useDispatch()
  const history = useHistory()

  const error = useSelector(state => state.error)
  const { data: adminsetting, loading: loadingAdminsetting } = useSelector(
    state => state.adminsetting,
  )
  const { token, isAuthenticated, user, isLoading } = useSelector(state => state.auth)
  // const { state, dispatch } = useForm(initialState)
  // const { logoutMsg } = state;

  const prevError = usePrev(error)
  const prevIsAuthenticated = usePrev(isAuthenticated)

  useEffect(() => {
    if (adminsetting == null && !loadingAdminsetting) {
      reduxDispatch(
        getAdminsetting('', ({ client }) => {
          window.localStorage.setItem('client-version', client)
        }),
      )
    }
  }, [reduxDispatch, adminsetting, loadingAdminsetting])

  useEffect(() => {
    if (error !== prevError) {
      if (error && error.id === 'AUTH_ERROR') {
        logoutFunc()
      }
    }
  }, [reduxDispatch, error, logoutFunc])

  useEffect(() => {
    const authLocationMatch =
      (window.location && window.location.pathname.match(noAuthAllowedPaths) === null) ||
      (typeof history != 'undefined' &&
        history.location.pathname.match(noAuthAllowedPaths) === null)
    const noAuthLocationMatch =
      (window.location && window.location.pathname.match(noAuthAllowedPaths) !== null) ||
      (typeof history != 'undefined' &&
        history.location.pathname.match(noAuthAllowedPaths) !== null)
    const allAllowedPathsMatch =
      (window.location && window.location.pathname.match(allAllowedPaths) !== null) ||
      (typeof history != 'undefined' && history.location.pathname.match(allAllowedPaths) !== null)

    const tokenDecoded = jwt.decode(token)
    if (
      (!tokenDecoded &&
        ((typeof history != 'undefined' &&
          history.location.pathname.match(noAuthAllowedPaths) === null) ||
          (window.location && window.location.pathname.match(noAuthAllowedPaths) === null))) ||
      (tokenDecoded && tokenDecoded.exp < Date.now() / 1000)
    ) {
      logoutFunc(allAllowedPathsMatch)
    } else if (token && !user && !isLoading) {
      store.dispatch(loadUser())
    }
    // if (logoutMsg !== prevState.logoutMsg) this.setState({ logoutMsg })

    let { to = '/login', toSuccess = '/' } = props
    setTimeout(() => {
      if (window.location && window.location.href.match(/logout/)) {
        store.dispatch(logout())
        if (history && typeof history.push != 'undefined') history.push('/login')
        else window.location.href = '/login'
        return
      }
      if (
        user &&
        window.localStorage.getItem('user_id') &&
        user._id !== window.localStorage.getItem('user_id')
      ) {
        // window.location.href = '/logout'
      } else if (!isAuthenticated || isAuthenticated !== prevIsAuthenticated) {
        if (!isAuthenticated || token == null) {
          if (to === '/') to = '/login'
        } else if ((isAuthenticated || token !== null) && user) {
          if (
            (window.location && window.location.pathname.match('register')) ||
            (history && history.location.pathname.match('register')) ||
            user.login_count === 1
          ) {
            if (tokenDecoded.user_type[0] === 'homeowner') {
              // toSuccess = '/homeowner-onboarding'
              // toSuccess = '/register-homeinformation'
              toSuccess = '/homeowner/home'
            }
            // else if (
            //   tokenDecoded.user_type[0] === 'builder' ||
            //   tokenDecoded.user_type[0] === 'realtor'
            // )
            //   toSuccess = '/' + tokenDecoded.user_type[0] + '-agent-profile'
            // else if (tokenDecoded.user_type[0] === 'property_manager')
            //   toSuccess = '/property-manager-profile' // if (tokenDecoded.user_type[0] === 'professional')
            else toSuccess = '/register-professional'
          } else if (!history || (history && history.location.state?.fromLogin)) {
            // toSuccess = '/' + tokenDecoded.user_type[0].replace(/_/g, '-') + '/home'
            if (
              window.localStorage.getItem('profileType') &&
              user.user_type_text.includes(window.localStorage.getItem('profileType'))
            ) {
              let userType = ''
              switch (window.localStorage.getItem('profileType')) {
                case config.user_types.homeowner:
                  userType = 'homeowner'
                  break
                // case config.user_types.realtor:
                //   userType = 'realtor'
                //   break
                case config.user_types.property_manager:
                  userType = 'property-manager'
                  break
                // case config.user_types.builder:
                //   userType = 'builder'
                //   break
                default:
                  userType = 'professional'
              }
              toSuccess = '/' + userType + '/home'
              window.setCookie('userType', userType)
            } else if (user.user_type.length > 1) {
              toSuccess = '/select-profile'
            } else {
              let userType = ''
              switch (user.user_type[0]) {
                case config.user_types.homeowner:
                  userType = 'homeowner'
                  break
                // case config.user_types.realtor:
                //   userType = 'realtor'
                //   break
                case config.user_types.property_manager:
                  userType = 'property-manager'
                  break
                // case config.user_types.builder:
                //   userType = 'builder'
                //   break
                default:
                  userType = 'professional'
              }
              toSuccess = '/' + userType + '/home'
              window.setCookie('userType', userType)
            }
          }
        }
        if (
          toSuccess.search(/homeowner/) === 1 &&
          user &&
          user.phone.replace(/[\s]/g, '') === '' &&
          ((history &&
            history.location.pathname.match('enterphone') === null &&
            history.location.pathname.match('select-profile') === null) ||
            (window.location.pathname.match('enterphone') === null &&
              window.location.pathname.match('select-profile') === null)) &&
          window.getCookie('skipEnterPhone') !== 'true'
        ) {
          toSuccess = '/homeowner-enterphone'
          setTimeout(() => {
            if (history) history.push(toSuccess)
            else window.location.href = toSuccess
          }, 1000)
        }

        // console.log({ to, toSuccess })
        // console.log('hasAuth: ' + isAuthenticated, ', auth-location: ' + authLocationMatch, ', no-auth-location: ' + noAuthLocationMatch, ', allAllowedPaths: ' + allAllowedPathsMatch)

        if (isAuthenticated === false && authLocationMatch && !allAllowedPathsMatch) {
          try {
            history.push(to)
          } catch (e) {
            // window.location.href = to;
          }
        } else if (isAuthenticated === true && noAuthLocationMatch && !allAllowedPathsMatch) {
          try {
            history.push(toSuccess)
          } catch (e) {
            // window.location.href = toSuccess;
          }
        }
      }
    }, 500)
  }, [logoutFunc, props, history, user, isAuthenticated, token, isLoading])

  const logoutFunc = allAllowedPathsMatch => {
    if (!logoutMsg && !allAllowedPathsMatch) {
      logoutMsg = true
      NotificationManager.info(
        'Your session has expired, click to login again',
        'Session Expired',
        5000,
        () => {
          history.push('/login')
        },
        true,
      )
      window.localStorage.setItem('session-exp-message', true)
      if (token) {
        window.localStorage.setItem('token', null)
        store.dispatch(logout())
      }
      if (history) {
        history.push('/login')
        if (history.location.pathname.search('/register/') === -1) {
          window.localStorage.setItem('login-redirect-page', history.location.pathname)
        }
      } else {
        if (window.location.pathname.search('/register/') === -1) {
          window.localStorage.setItem('login-redirect-page', window.location.pathname)
        }
        window.location.href = '/login'
      }
    }
  }

  return props.children
}

export default Authorize
