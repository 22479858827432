import {
  LOADING_CLIENTS,
  LOADED_CLIENTS,
  LOADING_WARRANTY_TEAM,
  LOADED_WARRANTY_TEAM,
  LOADING_WARRANTY_REQUESTS,
  LOADED_WARRANTY_REQUESTS,
  LOADING_CLIENT_STATUS,
  LOADED_CLIENT_STATUS,
  SAVING_CLIENT_STATUS,
  SAVED_CLIENT_STATUS,
  LOGOUT_SUCCESS,
  AUTH_ERROR,
} from '../actions/types'

const initialState = {
  isLoading: false,
  isSaving: false,
  clients: null,
  warranty_requests: null,
  warranty_team: null,
  client_status: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_CLIENTS:
    case LOADING_WARRANTY_TEAM:
    case LOADING_WARRANTY_REQUESTS:
    case LOADING_CLIENT_STATUS:
      return {
        ...state,
        isLoading: true,
      }
    case LOADED_CLIENTS:
    case LOADED_WARRANTY_TEAM:
    case LOADED_WARRANTY_REQUESTS:
    case LOADED_CLIENT_STATUS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      }
    case SAVING_CLIENT_STATUS:
      return {
        ...state,
        isSaving: true,
      }
    case SAVED_CLIENT_STATUS:
      return {
        ...state,
        ...action.payload,
        isSaving: false,
      }
    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
      window.localStorage.removeItem('token')
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}
