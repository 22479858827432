export const config = {
  hmPink: '#c7016a',
  hmPinkFade: '#f2c6de',
  hmBlue: '#25b5ff',
  hmPurple: '#6f42c1',
  hmGreen: '#7abe59',
  hmRed: '#d3233c',
  hmGrey: '#6c757d',
  hmGreyFade: '#f9f9f9',
  googleMap_key: 'AIzaSyBMHoBQHBGcMllSFM7rhOYEHxi01ZkQcNA',
  pwdValidationMsg: 'Password must be <b>strong</b> and <b>8 or more charaters long</b>.',
  defaultClientsponsorTemplate: `<p><strong>Dear [insert client name],</strong></p> <p>Congratulations on your new home and thank you so much for allowing me to help you. Other than the market appreciation the best way to increase your home’s value is to keep it well maintained. To show my appreciation for your business, I am sending you a 2-year HomeManager Plus membership.  You should have already received an email with a link to activate your account with HomeManager.</p> <p>Every month you will receive an email that prompts you about the latest safety, preventive maintenance, and energy efficiency tips on how to manage your new home better.</p> <p>It makes taking care of your new home easy and simple while increasing your home’s value, should you ever decide to re-sell it. You can go to <a href="https://homemanager.io">homemanager.io</a> to view a short HomeManager overview video.</p> <p>Again, thank you!  If you or anyone you know needs help selling or buying a home or you just want an updated “Home Market evaluation” please contact me directly.</p> <p>Sincerely,<br/>[agent_name]</p>`,
  defaultReconnectMessage:
    'I hope you and your family are enjoying your home! Thank you so much for trusting me to help find you a beautiful home. I am sending this to tell you of a website that helps you care for and keep your home in top condition. It’s called HomeManager, and it helps you maintain your home with monthly checklists and reminders on what should be done. We can even connect on HomeManager to allow you to get in touch with me easily. \n\nAlthough preventative maintenance is the primary benefit, there are many great features. \n\n1. Get your monthly list of recommended preventative maintenance activities to avoid damage to your home and increase its value. \n2. Keep a contact list of all your home care professionals. \n3. Keep records of all your related home invoices, documents, and uploaded electronic manuals for your equipment and appliances. \n4. Easily create a Home Inventory so you can accurately provide what items you own and their values to your insurance provider in cases of floods or fires. \n5. Determine a savings budget for the eventual replacement of items in your home. \n\nI am certain you will find HomeManager useful. Follow the link to start using HomeManager at no cost to you!',
  defaultInviteMessage: `I am putting together my contact list of my preferred contractors that I can call when I need work done around my home. My "Home Team." HomeManager is a new web service that allows me to contact you when I need your services. The service is free to you and allows me to contact you by phone or email to let you know what issues I am having. It sure would be great to have you as a part of my team.<br/><br/>Follow the link to create your account and verify your contact information is correct. Thanks again for being there when I need help.`,
  defaultGeneralSponsorshipTemplate: `<p><strong>Dear [insert friend's name],</strong></p> <br/><p>Congratulations! <br/>[agent_name] has purchased a HomeManager Plus membership for you. HomeManager is a digital tool that helps you manage your home. They help you save time and money while increasing the value of your home. They are your go to source for information or news when you need them. There are many great features you can explore at your leisure. The site is driven by simple, short videos to help you.</p> <br/><p>Welcome to HomeManager. We look forward to assisting you with your home needs.</p>`,
  reviewHeading: 'How would you rate my services?',
  reviewMessage:
    "We would like to get feedback about your experience working with us. Your response to this survey helps us improve our services further. We promise it won't take more than a minute.",
  defaultFolders: [
    'Maintenance',
    'Repair Cost',
    'Replacement Cost',
    'New Projects Cost',
    'Manuals',
    'Real Estate Documents',
    'Permits',
    'Construction Agreement',
    // "Contract Templates"
  ],
  investmentFolders: ['Maintenance', 'Repair Cost', 'Replacement Cost', 'New Projects Cost'],
  areaTypes: {
    interiors: [
      { id: 'Kitchen', name: 'Kitchen' },
      { id: 'Livingroom', name: 'Livingroom' },
      { id: 'Bedrooms', name: 'Bedrooms' },
      { id: 'Bathrooms', name: 'Bathrooms' },
      { id: 'Powder Rooms', name: 'Powder Rooms' },
      { id: 'Dining Room', name: 'Dining Room' },
      { id: 'Rec. Room', name: 'Rec. Room' },
      { id: 'Family Room', name: 'Family Room' },
      { id: 'Parlor', name: 'Parlor' },
      { id: 'Sun Room', name: 'Sun Room' },
      { id: 'Theater Room', name: 'Theater Room' },
      { id: 'Office', name: 'Office' },
      { id: 'Studio', name: 'Studio' },
      { id: 'Den', name: 'Den' },
      { id: 'Game Room', name: 'Game Room' },
      { id: 'Workout Room', name: 'Workout Room' },
      { id: 'Laundry Room', name: 'Laundry Room' },
      { id: 'Staircase', name: 'Staircase' },
      { id: 'Basement', name: 'Basement' },
      { id: 'Loft', name: 'Loft' },
      { id: 'Attic', name: 'Attic' },
      { id: 'Carport', name: 'Carport' },
      { id: 'Garage', name: 'Garage' },
      { id: 'Hallway', name: 'Hallway' },
      { id: 'Other', name: 'Other' },
    ],
    exteriors: [
      { id: 'Tile walls and floors', name: 'Tile walls and floors' },
      { id: 'Railings', name: 'Railings' },
      { id: `Decks and Patio’s `, name: `Decks and Patio’s ` },
      { id: 'Siding', name: 'Siding' },
      { id: 'Roofing', name: 'Roofing' },
      { id: 'Septic systems', name: 'Septic systems' },
      { id: 'Irrigation outside', name: 'Irrigation outside' },
      { id: 'Fire prevention inside', name: 'Fire prevention inside' },
      { id: 'Other', name: 'Other' },
    ],
  },
  floor_types: [
    { id: 'First Floor', name: 'First Floor' },
    { id: 'Second Floor', name: 'Second Floor' },
    { id: 'Third Floor', name: 'Third Floor' },
    { id: 'Basement', name: 'Basement' },
    { id: 'Attic', name: 'Attic' },
  ],
  space_types: {
    interior: [
      { id: 'Kitchen', name: 'Kitchen' },
      { id: 'Livingroom', name: 'Livingroom' },
      { id: 'Bedroom', name: 'Bedroom' },
      { id: 'Bathroom', name: 'Bathroom' },
      { id: 'Powder Room', name: 'Powder Room' },
      { id: 'Dining Room', name: 'Dining Room' },
      { id: 'Rec. Room', name: 'Rec. Room' },
      { id: 'Family Room', name: 'Family Room' },
      { id: 'Parlor', name: 'Parlor' },
      { id: 'Sun Room', name: 'Sun Room' },
      { id: 'Theater Room', name: 'Theater Room' },
      { id: 'Office', name: 'Office' },
      { id: 'Studio', name: 'Studio' },
      { id: 'Den', name: 'Den' },
      { id: 'Game Room', name: 'Game Room' },
      { id: 'Workout Room', name: 'Workout Room' },
      { id: 'Laundry Room', name: 'Laundry Room' },
      { id: 'Staircase', name: 'Staircase' },
      // { id: 'Basement', name: 'Basement' },
      { id: 'Loft', name: 'Loft' },
      // { id: 'Attic', name: 'Attic' },
      { id: 'Carport', name: 'Carport' },
      { id: 'Garage', name: 'Garage' },
      { id: 'Hallway', name: 'Hallway' },
    ],
    exterior: [
      {
        id: 'Front Side of House',
        name: 'Front Side of House',
      },
      {
        id: 'Back Side of House',
        name: 'Back Side of House',
      },
      { id: 'Detached Buildings', name: 'Detached Buildings' },
      { id: 'Pool/Hot Tub', name: 'Pool/Hot Tub' },
      { id: 'Patio/Deck', name: 'Patio/Deck' },
    ],
  },
  equipments_appliances: {
    interiorequipments: [
      {
        id: 'Electrical Panel Box & Sub-Panels',
        name: 'Electrical Panel Box & Sub-Panels',
      }, //
      { id: 'Sump Pump', name: 'Sump Pump' },
      { id: 'Water Softener', name: 'Water Softener' },
      { id: 'Bathroom Exhaust Fans', name: 'Bathroom Exhaust Fans' },
      {
        id: 'Air Registers ( Supply & Return )',
        name: 'Air Registers ( Supply & Return )',
      },
      { id: 'Thermostat', name: 'Thermostat' },
      { id: 'Fireplace', name: 'Fireplace' },
      { id: 'Water Heater', name: 'Water Heater' },
      { id: 'Furnaces', name: 'Furnaces' }, //
      { id: 'Well Pressure Tanks', name: 'Well Pressure Tanks' }, //
      { id: 'Radiators', name: 'Radiators' },
      { id: 'Wood Stoves', name: 'Wood Stoves' },
      { id: 'Sauna', name: 'Sauna' },
      { id: 'Attic Vents', name: 'Attic Vents' }, //
      { id: 'Window A/C Unit', name: 'Window A/C Unit' },
    ],
    interiorappliances: [
      {
        id: 'Smoke Detectors',
        name: 'Smoke Detectors',
      },
      {
        id: 'Carbon Monoxide Detectors',
        name: 'Carbon Monoxide Detectors',
      },
      { id: 'Humidifier & Dehumidifier', name: 'Humidifier & Dehumidifier' },
      { id: 'Coffee Machine', name: 'Coffee Machine' },
      { id: 'Toaster', name: 'Toaster' },
      { id: 'Microwave', name: 'Microwave' },
      { id: 'Vacuum Cleaner', name: 'Vacuum Cleaner' },
      { id: 'Inline Water Filter', name: 'Inline Water Filter' },
      { id: 'Refrigerator', name: 'Refrigerator' },
      { id: 'Freezer', name: 'Freezer' },
      { id: 'Trash Compactor', name: 'Trash Compactor' },
      { id: 'Dishwasher', name: 'Dishwasher' },
      { id: 'Clothes Dryer', name: 'Clothes Dryer' },
      { id: 'Washing Machine', name: 'Washing Machine' },
      { id: 'Exhaust Hood', name: 'Exhaust Hood' },
      { id: 'Range', name: 'Range' },
      { id: 'Oven', name: 'Oven' },
      { id: 'Cooktop Stove', name: 'Cooktop Stove' },
      { id: 'Portable A/C Unit', name: 'Portable A/C Unit' },
    ],
    exteriorequipments: [
      { id: 'Exterior Dryer Ducts', name: 'Exterior Dryer Ducts' }, //
      { id: 'Well Casing Cap', name: 'Well Casing Cap' },
      { id: 'Diversion Valve ', name: 'Diversion Valve ' }, //
      { id: 'A/C Unit', name: 'A/C Unit' },
      { id: 'Garage Door', name: 'Garage Door' },
      { id: 'Propane & Oil Tanks', name: 'Propane & Oil Tanks' },
      {
        id: 'Water Heater (Interior & Exterior)',
        name: 'Water Heater (Interior & Exterior)',
      },
    ],
    exteriorappliances: [{ id: 'BBQ Grill', name: 'BBQ Grill' }],
  },
  paints: {
    types: ['Water', 'Oil', 'Latex', 'Enamel'],
    application: ['Wall', 'Accent Wall', 'Ceiling', 'Trim', 'Doors', 'Flooring'],
  },
  materials: {
    types: ['Hardware', 'Light Fixtures', 'Plumbing Fixtures', 'Flooring', 'Ceiling', 'Walls'],
  },
  user_types: {
    homeowner: 'Homeowner',
    realtor: 'Real Estate Agent',
    builder: 'Builder',
    professional: 'Professional',
    property_manager: 'Property Manager',
  },
  professionals_types: {
    realtor: 'Real Estate Agent',
    builder: 'Builder',
    property_manager: 'Property Manager',
    home_insurance: 'Home Insurance',
    home_inspector: 'Home Inspector',
    mortgage_company: 'Mortgage Company',
    heating_air: 'Heating and Air',
    plumber: 'Plumber',
    electrician: 'Electrician',
    handyman: 'Handyman',
    cleaning_service: 'Cleaning Service',
    window_cleaning: 'Window Cleaning',
    carpet_cleaning: 'Carpet Cleaning',
    yard_maintenance: 'Yard Maintenance',
    sprinkler_service: 'Sprinkler Service',
    exterminators_pest: 'Exterminators & Pest Control',
    landscaping: 'Landscaping',
    gutter_cleaning: 'Gutter Cleaning',
    pool_spa_sauna_service: 'Pool, Spa & Sauna Service',
    power_washing: 'Power Washing',
    painters_caulking: 'Painters & Caulking',
    appliance_repairs: 'Appliance Repairs',
    furnace_duct_cleaning: 'Furnace Duct Cleaning',
    locksmith: 'Locksmith',
    snow_removal: 'Snow Removal',
    garage_door_service: 'Garage Door Service',
    roofing: 'Roofing',
    chimney_sweep: 'Chimney Sweep',
    septic_drain_cleaning: 'Septic & Drain Cleaning',
    fires_suppression_company: 'Fire Suppression Company',
    water_systems: 'Water Systems',
    solar_services: 'Solar Services',
    draftsman_architect: 'Draftsman/Architect',
  },
  // realtor - add can add to home team
  home_transaction_contacts: [
    'Home Insurance',
    // "Home Inspector",
    'Mortgage Company',
  ],
  userPermissionTypes: { 0: 'Tenant', 1: 'HomeOwner', 2: 'Property Manager' },
  // builder - add inventory
  default_devices: {
    refrigerator: ['Refrigerator', '/img/fridge.svg'],
    stove: ['Stove', '/img/stove.svg'],
    dishwasher: ['Dishwasher', '/img/dishes.svg'],
    microwave: ['Microwave', '/img/microwave.svg'],
    washing_machine: ['Washing Machine', '/img/washing-machine.svg'],
    dryer: ['Dryer', '/img/dryer.svg'],
    water_heater: ['Water Heater', '/img/water-heater.svg'],
    hvac: ['HVAC', '/img/hvac.svg'],
  },
  additional_info_options: [
    'How many years in business',
    'How many homes sold',
    'How many homes found for buyers',
    'Where did you grow up',
    'Family info',
    'Pets',
    'Hobbies',
    'About your company',
    'Warranty on work',
    'Other accereditations',
    'Favorite sports team',
  ],
  us_states: {
    AL: 'Alabama',
    AK: 'Alaska',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DC: 'District of Columbia',
    DE: 'Delaware',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
  },
  floorplan_levels: [
    // "Basic Floor Plan",
    'Basement',
    '1 Floor',
    '2 Floor',
    '3 Floor',
    '4 Floor',
    'Attic',
    'Property Plan',
  ],
  validFileExtensions: ['jpg', 'jpeg', 'gif', 'png', 'pdf', 'doc', 'docx', 'xlsx', 'xlsm'],
  customClientsCount: 300,
  customClientPrice: 1.5,
  clientInviteFeatures: [
    {
      name: 'Past Clients Invitations',
      id: 'past-clients',
      tooltip:
        'Applies to the individual client and home address associated with client. Branding is not transferable to a different home address.',
    },
    {
      name: 'Branding',
      id: 'branding',
      tooltip:
        'Every time the homeowner logs on to HomeManager, they will see your Face and/or logo.',
    },
    {
      name: 'Monthly Maintenance Reminder',
      id: 'monthly-maintenance',
      tooltip:
        'Every month HM will send reminders on what needs to be done around the home, which will have your picture and or logo as a sponsor to their service. ',
    },
    {
      name: 'Free Homeowner Account',
      id: 'free-homeowner',
      tooltip: 'Every agent gets a Free homeowner’s account for your personal use.',
    },
    {
      name: 'Home Evaluation Request',
      id: 'home-evaluation',
      tooltip:
        'Homeowners can easily connect with you as their agent to request an evaluation of their home. Provides an opportunity for you to get in front of them to acquire a listing or a sale. ',
    },
    {
      name: 'Chat',
      id: 'chat-plan',
      tooltip: 'Easily chat with your past clients on the HomeManager platform.',
    },
    {
      name: 'Referral Request',
      id: 'referral-request',
      tooltip:
        'Easily get referrals from past clients. Capture and manage referral data on HomeManager.',
    },
    {
      name: 'Obtain Online Reviews',
      id: 'obtain-online',
      tooltip:
        'You can seek reviews through HM from your preferred review site from your clients when needed. ',
    },
    {
      name: 'Find Listings',
      id: 'find-listings',
      tooltip:
        'Post a purchase alerts to existing clients that you have buyers who want to buy in their community. ',
    },
    {
      name: 'Bulk Client Upload',
      id: 'bulk-client',
      tooltip:
        'You can provide an excel spreadsheet of client information and HomeManager takes care of the rest. ',
    },
  ],
  account_status: {
    0: 'Invited',
    1: 'Active',
    2: 'Deleted',
    3: 'Suspended',
  },
  viewPage: {
    0: 'Account-Settings',
    1: 'Dashboard',
    2: 'Home Team',
    3: 'Maintenance',
    4: 'Property-Summary',
    5: 'Documents',
    6: 'Inventory',
    7: 'Replacement Budget',
    8: 'Emergency Floorplan',
    9: 'Builder Warranty Service',
    10: 'Monthly Rewards',
    100: 'Switch Account',
    101: 'Tips for Smart Homeowners',
    102: 'Social Media',
    103: 'Support Center',
  },
  gmPlacesFields: ['address_components', 'formatted_address', 'geometry'],
  gmPlacesTypes: ['address'],
  gmPlacesRestrict: { country: 'us' },
  userInviteSources: {
    '0': 'Manual',
    '1': 'vCard',
    '2': 'Excel',
    '3': 'CSV',
  },
  userInvitePlatforms: { '0': 'Web', '1': 'iOS', '2': 'Android' },
}
