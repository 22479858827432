import {
  LOADING_CLIENTS,
  LOADED_CLIENTS,
  LOADING_CLIENT_PERMISSIONS,
  LOADED_CLIENT_PERMISSIONS,
  SAVING_CLIENT_PERMISSIONS,
  SAVED_CLIENT_PERMISSIONS,
  SAVING_CLIENT_PROPERTY,
  SAVED_CLIENT_PROPERTY,
  LOADING_CLIENT_LEASEAGREEMENT,
  LOADED_CLIENT_LEASEAGREEMENT,
  SAVING_CLIENT_LEASEAGREEMENT,
  SAVED_CLIENT_LEASEAGREEMENT,
  LOGOUT_SUCCESS,
  AUTH_ERROR,
} from '../actions/types'

const initialState = {
  isLoading: false,
  isSaving: false,
  clients: null,
  permissions: null,
  leaseAgreement: null,
  tasks_status: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADING_CLIENTS:
    case LOADING_CLIENT_PERMISSIONS:
    case LOADING_CLIENT_LEASEAGREEMENT:
      return {
        ...state,
        isLoading: true,
      }
    case LOADED_CLIENTS:
    case LOADED_CLIENT_PERMISSIONS:
    case LOADED_CLIENT_LEASEAGREEMENT:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      }
    case SAVING_CLIENT_PERMISSIONS:
    case SAVING_CLIENT_PROPERTY:
    case SAVING_CLIENT_LEASEAGREEMENT:
      return {
        ...state,
        isSaving: true,
      }
    case SAVED_CLIENT_PERMISSIONS:
    case SAVED_CLIENT_PROPERTY:
    case SAVED_CLIENT_LEASEAGREEMENT:
      return {
        ...state,
        ...action.payload,
        isSaving: false,
      }
    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
      window.localStorage.removeItem('token')
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}
