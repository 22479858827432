import {
  LOGOUT_SUCCESS,
  USER_PROFILE_UPDATING,
  USER_PROFILE_UPDATED,
  USER_PROFILE_UPDATE_FAIL,
  WEATHER_LOADING,
  WEATHER_LOADED,
  WEATHER_ERROR,
  HOME_TEAM_LOADING,
  HOME_TEAM_LOADED,
  SAVED_HOME_TEAM,
  SAVING_HOME_TEAM,
  SAVING_CALEDAR,
  SAVED_CALEDAR,
  LISTING_USERS,
  LISTED_USERS,
  PROSPECTIVE_TEAM_LOADING,
  PROSPECTIVE_TEAM_LOADED,
  SAVING_PROSPECTIVE_TEAM,
  SAVED_PROSPECTIVE_TEAM,
  DISCOVER_LOADING,
  DISCOVER_LOADED,
  USER_PERMISSIONS_SUCCESS,
  ACCESS_USER_ACCOUNT,
  RECIEVER_LOADING,
  RECEIVER_DATA,
  VERIFYING_EMAIL,
  VERIFIED_EMAIL,
  VERIFYING_HOMETEAM,
  VERIFIED_HOMETEAM,
  SUBMITTING_FORGOT_PASSWORD,
  SUBMITED_FORGOT_PASSWORD,
  RESETING_PASSWORD,
  RESET_PASSWORD,
  UPDATING_EMAIL,
  UPDATED_EMAIL,
  USER_PROFILE_VIEW_SAVING,
  USER_PROFILE_VIEWED,
  LOADING_CONTACTS,
  LOADED_CONTACTS,
  SAVING_CONTACTS,
  SAVED_CONTACTS,
  DOWNLOADING_CONTACTS,
  DOWNLOADED_CONTACTS,
  AUTH_ERROR,
} from '../actions/types'

const initialState = {
  isLoading: false,
  inviteLoading: false,
  isSaving: false,
  weatherData: {
    temprature: '0',
    icon: 'none',
    summary: '',
    latitude: '',
    longitude: '',
  },
  home_team: null,
  warranty_team: null,
  prospective_team: null,
  pending_team: null,
  recommended_team: null,
  branding_team: null,
  recieverData: null,
  recieverLoading: false,
  new_professionals: null,
  ranking_professionals: null,
  search: [],
  userPermissions: null,
  userAccess: null,
  userDelegates: null,
  savingProfileView: false,
  contacts: null,
  contactsCount: null,
  loadingContacts: false,
  savingContacts: false,
  downloadingContacts: false,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case WEATHER_LOADING:
    case HOME_TEAM_LOADING:
    case LISTING_USERS:
    case PROSPECTIVE_TEAM_LOADING:
    case DISCOVER_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case WEATHER_LOADED:
    case HOME_TEAM_LOADED:
    case LISTED_USERS:
    case PROSPECTIVE_TEAM_LOADED:
    case USER_PERMISSIONS_SUCCESS:
    case DISCOVER_LOADED:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      }
    case USER_PROFILE_UPDATING:
    case SAVING_HOME_TEAM:
    case SAVING_CALEDAR:
    case SAVING_PROSPECTIVE_TEAM:
    case VERIFYING_EMAIL:
    case VERIFYING_HOMETEAM:
    case SUBMITTING_FORGOT_PASSWORD:
    case RESETING_PASSWORD:
    case UPDATING_EMAIL:
      return {
        ...state,
        isSaving: true,
      }
    case USER_PROFILE_UPDATE_FAIL:
    case USER_PROFILE_UPDATED:
      return {
        ...state,
        isSaving: false,
      }
    case SAVED_HOME_TEAM:
    case SAVED_CALEDAR:
    case VERIFIED_EMAIL:
    case VERIFIED_HOMETEAM:
    case SUBMITED_FORGOT_PASSWORD:
    case RESET_PASSWORD:
    case UPDATED_EMAIL:
      return {
        ...state,
        ...action.payload,
        isSaving: false,
      }
    case RECIEVER_LOADING:
      return {
        ...state,
        recieverLoading: !state.recieverLoading,
      }
    case RECEIVER_DATA:
      return {
        ...state,
        recieverData: action.payload,
      }
    case SAVED_PROSPECTIVE_TEAM:
      let prospective_update = state.prospective_team
      if (action.payload.action === 'add') {
        prospective_update.push(action.payload.prospective_team)
      } else if (action.payload.action === 'delete') {
        prospective_update.map((m, i) => {
          if (action.payload.prospective_team._id === m.prospective_id) delete prospective_update[i]
          return true
        })
        prospective_update = prospective_update.filter(m => {
          return typeof m != 'undefined' && m != null
        })
      }
      return {
        ...state,
        prospective_team: prospective_update,
        isSaving: false,
      }
    case WEATHER_ERROR:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      }
    case ACCESS_USER_ACCOUNT:
      window.localStorage.setItem('token', action.payload.token)
      window.localStorage.setItem('user_id', action.payload.user_id)
      return {
        ...state,
        ...action.payload,
      }
    case USER_PROFILE_VIEW_SAVING:
      return {
        ...state,
        savingProfileView: true,
      }
    case USER_PROFILE_VIEWED:
      return {
        ...state,
        ...action.payload,
        savingProfileView: false,
      }
    case LOADING_CONTACTS:
      return {
        ...state,
        loadingContacts: true,
      }
    case LOADED_CONTACTS:
      return {
        ...state,
        ...action.payload,
        loadingContacts: false,
      }
    case SAVING_CONTACTS:
      return {
        ...state,
        savingContacts: true,
      }
    case SAVED_CONTACTS:
      return {
        ...state,
        savingContacts: false,
      }
    case DOWNLOADING_CONTACTS:
      return {
        ...state,
        downloadingContacts: true,
      }
    case DOWNLOADED_CONTACTS:
      return {
        ...state,
        ...action.payload,
        downloadingContacts: false,
      }

    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('user_id')
      window.localStorage.getItem('profileType')
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}
