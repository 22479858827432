import {
  FOLDERS_LOADING,
  GET_FOLDERS,
  FOLDERS_LOADED,
  FOLDER_ERROR,
  FOLDER_CREATED,
  DOCUMENT_LOADING,
  DOCUMENT_LOADED,
  DOCUMENT_ADDED,
  DOCUMENT_ERROR,
  FOLDER_DOWNLOADED,
  FILE_DOWNLOAD,
  HISTORICAL_DATA_LOADING,
  HISTORICAL_DATA_LOADED,
  HISTORICAL_DATA_ERROR,
  AUTH_ERROR,
  LOGOUT_SUCCESS,
} from '../actions/types'

const initialState = {
  loading: false,
  documentLoading: false,
  uploadLoading: false,
  documentAdded: false,
  folderCreated: false,
  downloadFilePath: null,
  userFolders: [],
  documents: [],
  downloadingHistoricalReport: false,
  error: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case FOLDER_DOWNLOADED:
    case FILE_DOWNLOAD:
      // console.log('payload: ', action.payload)
      return {
        ...state,
        downloadFilePath: action.payload,
      }
    case FOLDERS_LOADING:
    case FOLDERS_LOADED:
      return {
        ...state,
        loading: !state.loading,
        documentAdded: false,
      }

    case DOCUMENT_LOADING:
      return {
        ...state,
        documentLoading: true,
        documentAdded: false,
        uploadLoading: true,
        loading: false,
      }
    case DOCUMENT_LOADED:
      return {
        ...state,
        documentLoading: false,
        uploadLoading: false,
        documents: action.payload.data,
      }
    case FOLDER_CREATED:
      return {
        ...state,
        folderCreated: !state.folderCreated,
      }
    case DOCUMENT_ADDED:
      return {
        ...state,
        documentAdded: true,
        documentLoading: false,
        uploadLoading: false,
        loading: false,
      }
    case GET_FOLDERS:
      return {
        ...state,
        userFolders: action.payload.data,
      }
    case FOLDER_ERROR:
    case DOCUMENT_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case HISTORICAL_DATA_LOADING:
    case HISTORICAL_DATA_LOADED:
    case HISTORICAL_DATA_ERROR:
      return {
        ...state,
        downloadingHistoricalReport: !state.downloadingHistoricalReport,
      }
    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
      window.localStorage.removeItem('token')
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}
