import axios from 'axios'
import { returnErrors } from './errorActions'
import jwt from 'jsonwebtoken'

import {
  LOADED_AUTHCONFIG,
  USER_LOADED,
  USER_LOADING,
  USER_PROFILE_UPDATED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  CLEAR_ERRORS,
} from './types'

const baseurl = process.env.REACT_APP_SERVER_URL + '/api/auth/'

export const authConfig = (req, cb) => dispatch => {
  axios
    .get(baseurl)
    .then(res => {
      dispatch({
        type: LOADED_AUTHCONFIG,
        payload: typeof res.data != 'undefined' ? { ...res.data } : {},
      })
      if (cb) {
        cb(true)
      }
    })
    .catch(err => {
      if (err && err.response && typeof err.response.data != 'undefined') {
        if (cb) {
          cb(false)
        }
      }
    })
}

// Login User
export const login = ({ email, password, remember, social_login }, cb) => (dispatch, getState) => {
  // Request body
  const body = JSON.stringify({ email, password, remember, social_login, source: 'web' })

  dispatch({
    type: CLEAR_ERRORS,
  })
  axios
    .post(baseurl, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: typeof res.data != 'undefined' ? { ...res.data } : {},
      })
      if (cb) {
        cb(true, res.data.user)
      }
    })
    .catch(err => {
      if (err && err.response && typeof err.response.data != 'undefined')
        dispatch(
          returnErrors(
            err.response.data.msg,
            err.response.status,
            'LOGIN_FAIL',
            err.response.data.info,
          ),
        )
      dispatch({
        type: LOGIN_FAIL,
      })
      if (cb) {
        cb(false)
      }
    })
}

// Register User
export const register = (req, cb) => (dispatch, getState) => {
  // Request body
  const body = JSON.stringify({ ...req, source: 'web' })

  axios
    .post(process.env.REACT_APP_SERVER_URL + '/api/users/register', body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: REGISTER_SUCCESS,
        payload: typeof res.data != 'undefined' ? res.data : {},
      })
      if (cb) cb(true)
    })
    .catch(err => {
      if (err && err.response && typeof err.response.data != 'undefined')
        dispatch(returnErrors(err.response.data.msg, err.response.status, 'REGISTER_FAIL'))
      dispatch({
        type: REGISTER_FAIL,
      })
      if (cb) cb(false)
    })
}

// Check token & load user
export const loadUser = (req, cb) => (dispatch, getState) => {
  const config = tokenConfig(getState)
  let { userTypeText } = req ? req : {}
  const token = window.localStorage.getItem('token')
  if (!userTypeText && token) {
    const tokenDecoded = jwt.decode(token)
    if (tokenDecoded) {
      userTypeText = tokenDecoded.user_type_text ? tokenDecoded.user_type_text[0] : ''
      config.headers['x-auth-token'] = token
    }
  }
  // User loading
  dispatch({ type: USER_LOADING })

  axios
    .get(baseurl + 'user' + (userTypeText ? '?userTypeText=' + userTypeText : ''), config)
    .then(res => {
      if (userTypeText) {
        window.localStorage.setItem('profileType', userTypeText)
      }
      dispatch({
        type: USER_LOADED,
        payload: typeof res.data != 'undefined' ? { ...res.data } : {},
      })
      if (cb) {
        const switchUserType =
          res.data.user.user_type[0] !== 'property_manager'
            ? res.data.user.user_type[0]
            : 'property-manager'
        // console.log(res.data.user.user_type[0], switchUserType)
        cb(switchUserType)
      }
    })
    .catch(err => {
      if (err && err.response && typeof err.response.data != 'undefined')
        dispatch(returnErrors(err.response.data.msg, err.response.status, 'AUTH_ERROR'))
      dispatch({
        type: AUTH_ERROR,
      })
      if (cb) cb()
    })
}

export const adminLoggedInAsUser = (adminToken, cb) => async (dispatch, getState) => {
  // await dispatch(logout('', () => {}));
  // Request body
  const body = JSON.stringify(adminToken)

  dispatch({ type: USER_LOADING })
  axios
    .post(baseurl + 'admin-login-as', body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: typeof res.data != 'undefined' ? { ...res.data } : {},
      })
      if (cb) cb(res.data)
    })
    .catch(err => {
      if (err && err.response && typeof err.response.data != 'undefined') {
        dispatch(returnErrors(err.response.data.msg, err.response.status, 'LOGIN_FAIL'))
      }
      dispatch({ type: LOGIN_FAIL })
      if (cb) cb(null)
    })
}

// Logout User
export const logout = (req, cb) => (dispatch, getState) => {
  axios
    .post(baseurl + 'logout', {}, tokenConfig(getState))
    .then(res => {
      // setTimeout(() => {
      // window.location.href = "/login";
      // }, 100)
      dispatch({
        type: LOGOUT_SUCCESS,
      })
      if (cb) cb()
    })
    .catch(err => {
      // window.location.href = "/login";
      if (err && err.response && typeof err.response.data != 'undefined')
        dispatch(returnErrors(err.response.data.msg, err.response.status, 'AUTH_ERROR'))
      dispatch({
        type: AUTH_ERROR,
      })
      if (cb) cb()
    })
}

export const updateUserProps = (req, cb) => dispatch => {
  dispatch({
    type: USER_PROFILE_UPDATED,
    payload: { user: { ...req } },
  })
}

// Setup config/headers and token
export const tokenConfig = getState => {
  // Get token from localstorage
  const token = getState().auth.token

  // Headers
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  }

  // If token, add to headers
  if (token) {
    config.headers['x-auth-token'] = token
  }

  return config
}
